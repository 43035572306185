export const ROOT = '/';
export const LOGIN = '/login';
export const AUTH = '/auth';
export const REGISTER = '/register';
export const CONFIRM = '/confirm';
export const PLAYLIST = '/tabs/home/playlist';
export const WELCOME = '/welcome';
export const RESET_PASSWORD = '/reset-password';
export const SET_PASSWORD = '/set-password';
export const TABS = '/tabs';
export const HOME = '/tabs/home';
export const EXPLORE = '/tabs/explore';
export const HELP = '/tabs/help';
export const MORE = '/tabs/more';
export const AWARDS = '/tabs/awards';

export const QUIZ = '/tabs/home/playlist/quiz';
export const SURVEY = '/tabs/home/playlist/survey';

export const PROFILE = '/tabs/more/profile';
export const SETTINGS = '/tabs/more/settings';
export const APP_TERMS = '/tabs/more/app-terms';

export const EXPLORE_DETAILS = '/explore/details';

export const CHAT = '/tabs/help/chat';
export const MEDICAL = '/tabs/help/medical';
export const REPORT = '/tabs/help/report';
export const POLICY = '/tabs/help/policy';
export const CONTACTS = '/tabs/help/contacts';

export const IS_AUTHENTICATED = 'isAuthenticated';
export const STUDENT = 'student';
export const CURRENT_USER_ATTRIBUTES = 'currentUserAttributes';

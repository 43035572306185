import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  useIonToast,
} from '@ionic/react';
import { Auth } from 'aws-amplify';
import React, { memo, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LOGIN } from '../../constants';
import { AuthContext, setCurrentUserAttributes } from '../../reducers/auth';
import { getToastParams } from '../../utils/toastParams';
import './style.scss';

const ConfirmationPage: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const history = useHistory();
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentUserAttributes, authDispatch } = useContext(AuthContext);

  const isDisabled = !code || isLoading;

  const confirmSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(currentUserAttributes.email, code);
      authDispatch(
        setCurrentUserAttributes({
          ...currentUserAttributes,
          email_verified: true,
        }),
      );
      history.replace(LOGIN);
    } catch (error: any) {
      console.log('error confirmSignUp', error);
      present(
        getToastParams(
          {
            message: error.message,
          },
          dismiss,
        ),
      );
      setIsLoading(false);
    }
  };

  const resendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await Auth.resendSignUp(currentUserAttributes.email);
      console.log('code resent successfully');
      present(
        getToastParams(
          {
            color: 'primary',
            message: `Email sent to: ${currentUserAttributes.email}`,
          },
          dismiss,
        ),
      );
    } catch (error: any) {
      console.log('error resendEmail: ', error);
      present(
        getToastParams(
          {
            message: error.message,
          },
          dismiss,
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IonPage id="confirmation-page">
      <IonContent fullscreen>
        <IonRow>
          <IonCol>
            <IonText className="auth-pages-text">
              <h1>Email Verification</h1>
              <p>
                Look for the verification email in your inbox and click the link
                in that email.
                <br />A confirmation message will appear in your web browser.
              </p>
            </IonText>
          </IonCol>
        </IonRow>
        <IonGrid>
          <IonRow>
            <IonCol>
              <form className="form form-confirmation" onSubmit={confirmSignUp}>
                <IonButton
                  size="large"
                  type="submit"
                  expand="block"
                  onClick={() => history.push(LOGIN)}
                  className="custom-button-margin-top"
                >
                  Back to Log In
                </IonButton>
                <IonText className="ion-text-center">
                  <p>
                    Did not get the email?{' '}
                    <a href="#1" onClick={resendEmail}>
                      Send Again
                    </a>
                  </p>
                </IonText>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(ConfirmationPage);

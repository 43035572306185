import {
  IonContent,
  IonToolbar,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonLoading,
  useIonToast,
} from '@ionic/react';
import { DataStore } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';

import Accordion from '../../components/Accordion';
import { University } from '../../models';
import { StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

const PolicyPage: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const { student } = useContext(StudentContext);
  const { universityID } = student || {};
  const [university, setUniversity] = useState<University>();
  const { legal, policy } = university || {};
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (universityID) {
      (async () => {
        try {
          const data = await DataStore.query(University, universityID);
          setUniversity(data);
        } catch (error: any) {
          console.log('error fecthing university:', error);
          present(
            getToastParams(
              {
                message: error.message,
              },
              dismiss,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityID]);

  return (
    <IonPage id="policy-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Policy</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Accordion title="Policies" description={policy} />
        <Accordion title="Laws" description={legal} />
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default PolicyPage;

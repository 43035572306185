import { AmplifyS3Image } from '@aws-amplify/ui-react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from '@ionic/react';
import { lockClosedOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PLAYLIST } from '../../constants';
import type { Award } from '../../models';
import CustomModal from '../CustomModal';
import './styles.scss';

interface AwardCardProps {
  award: Award;
  playlistID: string;
  completed: boolean;
}

const AwardCard: React.FC<AwardCardProps> = ({
  award,
  playlistID,
  completed,
}) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  const { title, image, description } = award;
  const goToPlaylist = (e: React.MouseEvent) => {
    e.stopPropagation();
    history.push(PLAYLIST, { playlistID });
  };

  const buildCta = () => {
    return (
      <IonButton
        onClick={e => {
          setShowModal(false);
          goToPlaylist(e);
        }}
      >
        Watch Playlist
      </IonButton>
    );
  };

  return (
    <div id="award-card">
      <IonCard onClick={() => setShowModal(!completed ? true : false)}>
        {!completed && (
          <div className="card-lock">
            <IonIcon src={lockClosedOutline} size="large" />
          </div>
        )}
        <AmplifyS3Image imgKey={image} />
        <IonCardHeader>
          <IonCardTitle>{title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>{description}</p>
        </IonCardContent>
      </IonCard>
      {showModal && (
        <CustomModal
          hasClose
          title="You haven't earned this award yet"
          description={description}
          amplifyImage={image}
          cta={buildCta()}
          onDidDismiss={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default AwardCard;

import { IonRow, IonGrid, IonCol } from '@ionic/react';
import React from 'react';

import type { Contact } from '../../models';
import ContactItem from '../ContactItem';

import './style.scss';

interface ContactsListProps {
  contacts: Contact[];
}

const ContactsList: React.FC<ContactsListProps> = ({ contacts }) => {
  return (
    <div id="contacts-list">
      <IonGrid>
        <IonRow>
          <IonCol>
            {contacts.map(contact => (
              <ContactItem key={contact.title} contact={contact} />
            ))}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ContactsList;

import React from 'react';
import Slider from 'react-slick';

import './styles.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface CustomCarouselProps {
  children: React.ReactNode;
  slidesLength: Number;
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
  children,
  slidesLength,
}) => {
  const settings = {
    arrows: true,
    infinite: true,
    slidesToShow: slidesLength >= 2 ? 2 : 1,
    centerMode: true,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: '0px',
  };

  return (
    <div id="custom-carousel">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default CustomCarousel;

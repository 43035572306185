import type { Dispatch } from 'react';
import { createContext } from 'react';

import { CURRENT_USER_ATTRIBUTES, IS_AUTHENTICATED } from '../constants';
import { setToLocalStorage } from '../utils/localStorage';

export const authInitialState = {
  isAuthenticated: false,
  currentUserAttributes: {
    email: '',
  },
  authDispatch: (): null => null,
};

const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
const SET_CURRENT_USER_ATTRIBUTES = 'SET_CURRENT_USER_ATTRIBUTES';

type State = {
  isAuthenticated: boolean;
  currentUserAttributes: CurrentUserAttributes;
  authDispatch: Dispatch<Action>;
};

type Action = ReturnType<
  typeof setAuthStatus | typeof setCurrentUserAttributes
>;

type CurrentUserAttributes = {
  email: string;
  email_verified?: boolean;
  sub?: string;
  contact?: string;
};

export const setAuthStatus = (authStatus: boolean) =>
  <const>{
    type: SET_AUTH_STATUS,
    authStatus,
  };

export const setCurrentUserAttributes = (
  currentUserAttributes: CurrentUserAttributes,
) =>
  <const>{
    type: SET_CURRENT_USER_ATTRIBUTES,
    currentUserAttributes,
  };

export const AuthReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_AUTH_STATUS:
      setToLocalStorage(IS_AUTHENTICATED, action.authStatus);
      return {
        ...state,
        isAuthenticated: action.authStatus,
      };
    case SET_CURRENT_USER_ATTRIBUTES:
      setToLocalStorage(CURRENT_USER_ATTRIBUTES, action.currentUserAttributes);
      return {
        ...state,
        currentUserAttributes: action.currentUserAttributes,
      };
    default:
      return state;
  }
};

export const AuthContext = createContext<State>(authInitialState);

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  useIonToast,
} from '@ionic/react';
import { Auth } from 'aws-amplify';
import React, { memo, useState } from 'react';
import { getToastParams } from '../../utils/toastParams';

const ResetPasswordPage: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const forgotPassword = async () => {
    setIsLoading(true);
    try {
      await Auth.forgotPassword(email);
      present(
        getToastParams(
          {
            message: 'Please, check your email to reset your password.',
          },
          dismiss,
        ),
      );
    } catch (error: any) {
      console.log('forgotPassword', error);
      present(
        getToastParams(
          {
            message: error.message,
          },
          dismiss,
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlerOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    forgotPassword();
  };

  const isDisabled = !email ? true : false;

  return (
    <IonPage id="reset-password-page">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1>Reset your Password</h1>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <form onSubmit={handlerOnSubmit}>
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput
                    required
                    type="email"
                    value={email}
                    onIonChange={e => {
                      setEmail(e.detail.value || '');
                    }}
                  />
                </IonItem>
                <IonButton
                  size="large"
                  type="submit"
                  expand="block"
                  className="custom-button-margin-top"
                  disabled={isDisabled}
                >
                  Submit
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(ResetPasswordPage);

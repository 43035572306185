import {
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonImg,
} from '@ionic/react';
import { caretForward } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';

interface CustomCardProps {
  page: string;
  href?: string;
  title: string;
  icon?: string;
  image?: string;
  subtitle: string;
}

const CustomCard: React.FC<CustomCardProps> = ({
  icon,
  page,
  title,
  image,
  subtitle,
  href,
}) => {
  const history = useHistory();

  const goToPage = (e: React.MouseEvent) => {
    e.stopPropagation();
    history.push(page);
  };

  const ionCard = (children: any) => {
    if (href) {
      return (
        <a style={{ textDecoration: 'none' }} href={href}>
          <IonCard>{children}</IonCard>
        </a>
      );
    }
    return <IonCard onClick={goToPage}>{children}</IonCard>;
  };

  return (
    <div id="custom-card">
      {ionCard(
        <>
          <div className={`main-icon ${image ? 'image' : ''}`}>
            {icon && <IonIcon size="large" color="primary" src={icon} />}
            {image && <IonImg src={image}></IonImg>}
          </div>
          <div className="title-subtitle">
            <IonCardTitle>{title}</IonCardTitle>
            <IonCardSubtitle>{subtitle}</IonCardSubtitle>
          </div>
          <IonIcon className="caret-icon" size="small" src={caretForward} />
        </>,
      )}
    </div>
  );
};

export default CustomCard;

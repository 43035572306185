import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { person, settings, lockClosed } from 'ionicons/icons';
import React, { memo } from 'react';

import CustomCard from '../../components/CustomCard';
import { PROFILE, SETTINGS, APP_TERMS } from '../../constants';
import { useSignOut } from '../../hooks/useCleanLocalStorage';

import './style.scss';

const Cards = [
  {
    title: 'My Profile',
    subtitle: 'Edit Profile',
    icon: person,
    page: PROFILE,
  },
  {
    title: 'My Settings',
    subtitle: 'Edit Settings',
    icon: settings,
    page: SETTINGS,
  },
  {
    title: 'Terms & Conditions',
    subtitle: 'View mobile app T&C',
    icon: lockClosed,
    page: APP_TERMS,
  },
];

const MorePage: React.FC = () => {
  const [signOut] = useSignOut();

  return (
    <IonPage id="more-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>More</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          {Cards.map(({ title, subtitle, icon, page }) => (
            <IonRow key={title}>
              <IonCol>
                <CustomCard
                  title={title}
                  subtitle={subtitle}
                  icon={icon}
                  page={page}
                />
              </IonCol>
            </IonRow>
          ))}
          <IonRow id="rowLogout">
            <IonCol className="ion-text-center ion-margin-top">
              <a href="#1" onClick={signOut}>
                Logout
              </a>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default memo(MorePage);

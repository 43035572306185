import { AmplifyS3Image } from '@aws-amplify/ui-react';
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React from 'react';

import AvatarImage from '../../assets/images/avatar.png';
import type { Contact } from '../../models';

import './style.scss';

interface ContactsPageProps {
  contact: Contact;
  onDidDismiss: () => void;
}

const ContactModal: React.FC<ContactsPageProps> = ({
  contact,
  onDidDismiss,
}) => {
  const { title, name, image, phone, email, description } = contact;
  return (
    <IonModal isOpen cssClass="contact-modal" onDidDismiss={onDidDismiss}>
      <IonIcon
        size="large"
        className="icon-close"
        src={closeOutline}
        onClick={onDidDismiss}
      />
      <IonItem lines="none" className="ion-padding">
        <IonAvatar slot="start">
          {image ? (
            <AmplifyS3Image imgKey={image} />
          ) : (
            <IonImg src={AvatarImage} />
          )}
        </IonAvatar>
        <IonLabel style={{ whiteSpace: 'unset', overflow: 'unset' }}>
          <strong className="name">{name}</strong>
          <span>{title}</span>
        </IonLabel>
      </IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>{description}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton href={`tel:${phone}`} size="large" expand="block">
              Call
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton
              href={`mailto:${email}`}
              size="large"
              expand="block"
              fill="outline"
            >
              Send Message
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default ContactModal;

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonThumbnail,
} from '@ionic/react';
import { thumbsUp } from 'ionicons/icons';
import React from 'react';

import QuizImage from '../../assets/images/quiz.svg';
import SurveyImage from '../../assets/images/survey.svg';
import type { GoToPageType } from '../../hooks/useNextContent';
import type { Content } from '../../models';
import VimeoThumbnail from '../VimeoThumbnail';

import './styles.scss';

interface ContentCardProps {
  goToPage: GoToPageType;
  content: Content;
  completed: boolean;
  scrollTo: () => void;
}

const ContentCard: React.FC<ContentCardProps> = ({
  content,
  goToPage,
  completed,
  scrollTo,
}) => {
  const buildCompletedIcon = () => {
    return completed ? (
      <div className="right-icon completed">
        <IonIcon color="primary" icon={thumbsUp} />
      </div>
    ) : (
      <div className="right-icon">
        <IonIcon icon={thumbsUp} />
      </div>
    );
  };

  const renderCard = () => {
    const { Video: video, Quiz: quiz, Survey: survey } = content;
    if (video) {
      const { title, englishUrl, description } = video as any;
      return (
        <IonCard
          onClick={() => {
            goToPage(content);
            scrollTo();
          }}
        >
          {buildCompletedIcon()}
          <div className="thumbnail">
            <IonCardHeader>
              <VimeoThumbnail videoURL={englishUrl} />
              <IonCardTitle>{title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>{description}</IonCardContent>
          </div>
        </IonCard>
      );
    }
    if (quiz) {
      const { title } = quiz;
      return (
        <IonCard onClick={() => goToPage(content)}>
          {buildCompletedIcon()}
          <IonThumbnail>
            <IonImg src={QuizImage} />
          </IonThumbnail>
          <div className="thumbnail">
            <IonCardHeader>
              <IonCardTitle>{title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>Let’s test your knowledge</IonCardContent>
          </div>
        </IonCard>
      );
    }
    if (survey) {
      const { title } = survey;
      return (
        <IonCard onClick={() => goToPage(content)}>
          {buildCompletedIcon()}
          <IonThumbnail>
            <IonImg src={SurveyImage} />
          </IonThumbnail>
          <div className="thumbnail">
            <IonCardHeader>
              <IonCardTitle>{title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>Take our survey</IonCardContent>
          </div>
        </IonCard>
      );
    }
  };

  return <div id="content-card">{renderCard()}</div>;
};

export default ContentCard;

// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const WatchedStatus = {
  "STARTED": "STARTED",
  "COMPLETED": "COMPLETED"
};

const Category = {
  "SEXUAL_HARASSMENT_PREVENTION": "SEXUAL_HARASSMENT_PREVENTION",
  "BYSTANDER_TRAINING": "BYSTANDER_TRAINING",
  "SEXUAL_ASSAULT_PREVENTION": "SEXUAL_ASSAULT_PREVENTION",
  "DATING_VIOLENCE_PREVENTION": "DATING_VIOLENCE_PREVENTION",
  "HEALTHY_RELATIONSHIPS": "HEALTHY_RELATIONSHIPS",
  "ALCOHOL_AND_DRUG_AWARENESS": "ALCOHOL_AND_DRUG_AWARENESS",
  "MENTAL_HEALTH": "MENTAL_HEALTH",
  "ANXIETY": "ANXIETY",
  "DEPRESSION": "DEPRESSION",
  "BODY_IMAGE": "BODY_IMAGE",
  "TRAUMA": "TRAUMA",
  "INTIMATE_PARTNER_VIOLENCE": "INTIMATE_PARTNER_VIOLENCE",
  "CONTROLLING_RELATIONSHIPS": "CONTROLLING_RELATIONSHIPS",
  "BOUNDARIES": "BOUNDARIES",
  "TITLE_IX": "TITLE_IX",
  "SEXUAL_HEALTH": "SEXUAL_HEALTH",
  "SCENARIOS": "SCENARIOS",
  "IS_IT_LOVE": "IS_IT_LOVE",
  "IS_MY_RELATIONSHIP_HEALTHY": "IS_MY_RELATIONSHIP_HEALTHY",
  "DO_YOU_PRACTICE_CONSENT": "DO_YOU_PRACTICE_CONSENT",
  "SURVIVOR_TIPS": "SURVIVOR_TIPS",
  "COPING_WITH_ANXIETY": "COPING_WITH_ANXIETY",
  "IS_MY_ALCOHOL_USE_HEALTHY": "IS_MY_ALCOHOL_USE_HEALTHY",
  "HOW_TO_BE_INCLUSIVE": "HOW_TO_BE_INCLUSIVE",
  "ADDRESSING_RACISM": "ADDRESSING_RACISM",
  "HOW_TO_BE_ANTI_RACIST": "HOW_TO_BE_ANTI_RACIST",
  "MANAGING_STRESS": "MANAGING_STRESS",
  "MANAGING_TRAUMA": "MANAGING_TRAUMA",
  "GETTING_SUPPORT": "GETTING_SUPPORT",
  "ASSAULT": "ASSAULT"
};

const Location = {
  "CAMPUS": "CAMPUS",
  "COMMUNITY": "COMMUNITY"
};

const Conversation = {
  "CONFIDENTIAL": "CONFIDENTIAL",
  "NONCONFIDENTIAL": "NONCONFIDENTIAL"
};

const Gender = {
  "FEMALE": "FEMALE",
  "MALE": "MALE",
  "NONBINARY": "NONBINARY",
  "CHOOSE_NOT_TO_ANSWER": "CHOOSE_NOT_TO_ANSWER"
};

const UniversityYear = {
  "FIRST_YEAR_STUDENT": "FIRST_YEAR_STUDENT",
  "SECOND_YEAR_STUDENT": "SECOND_YEAR_STUDENT",
  "THIRD_YEAR_STUDENT": "THIRD_YEAR_STUDENT",
  "FOURTH_YEAR_STUDENT": "FOURTH_YEAR_STUDENT",
  "GRADUATE_STUDENT": "GRADUATE_STUDENT",
  "STAFF": "STAFF",
  "OTHER" : "OTHER"
};

const Language = {
  "ENGLISH": "ENGLISH",
  "CANTONESE": "CANTONESE",
  "MANDARIN": "MANDARIN",
  "SPANISH": "SPANISH"
};

const { WatchedPlaylist, Answers, Report, Contact, Explore, Infographic, Graphic, Video, UniversityExplore, University, Student, UniversityPlaylist, Playlist, Content, Survey, Question, Quiz, Award } = initSchema(schema);

export {
  WatchedPlaylist,
  Answers,
  Report,
  Contact,
  Explore,
  Infographic,
  Graphic,
  Video,
  UniversityExplore,
  University,
  Student,
  UniversityPlaylist,
  Playlist,
  Content,
  Survey,
  Question,
  Quiz,
  Award,
  WatchedStatus,
  Category,
  Location,
  Conversation,
  Gender,
  UniversityYear,
  Language
};
import {
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import React, { memo, useContext, useEffect, useState } from 'react';

import AwardsCarousel from '../../components/AwardsCarousel';
import CustomAvatar from '../../components/CustomAvatar';
import PlaylistGrid from '../../components/PlaylistGrid';
import ProgressCard from '../../components/ProgressCard';
import TermsAndConditions from '../../components/TermsAndConditions';
import { useDatastoreQuery } from '../../hooks/useDatastoreQuery';
import type { Playlist } from '../../models';
import { StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

const HomePage: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const { student } = useContext(StudentContext);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const {
    username,
    gender,
    universityID,
    completedPlaylists = [],
    acceptedTerms,
  } = student || ({} as any);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { queryPlaylists } = useDatastoreQuery(universityID);
  const requiredPlaylists = playlists.filter(({ mandatory }) => mandatory);
  const recommendedPlaylists = playlists.filter(({ mandatory }) => !mandatory);

  useEffect(() => {
    let isMounted = true;
    if (universityID) {
      (async () => {
        try {
          const data = await queryPlaylists();
          if (isMounted) setPlaylists(data);
        } catch (error: any) {
          console.log('error fetch playlist:', error);
          present(
            getToastParams(
              {
                message: error.message,
              },
              dismiss,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      })();
      return () => {
        isMounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityID]);

  return (
    <IonPage id="home-page">
      {!acceptedTerms ? (
        <TermsAndConditions />
      ) : (
        <>
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                <div className="title-container">
                  <CustomAvatar gender={gender} />
                  Hi {username}!
                </div>
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <ProgressCard
              playlists={playlists}
              completedPlaylists={completedPlaylists}
            />
            <PlaylistGrid
              title="Required Playlists"
              playlists={requiredPlaylists}
              completedPlaylists={completedPlaylists}
            />
            <PlaylistGrid
              title="Explore suggested videos"
              playlists={recommendedPlaylists}
              completedPlaylists={completedPlaylists}
            />
            <AwardsCarousel
              title="Awards"
              playlists={playlists}
              completedPlaylists={completedPlaylists}
            />
          </IonContent>
          <IonLoading isOpen={isLoading} message={'Please wait...'} />
        </>
      )}
    </IonPage>
  );
};

export default memo(HomePage);

import {
  IonButton,
  IonSelect,
  IonSelectOption,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonToolbar,
  IonPage,
  IonRow,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonLabel,
} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CONTACTS } from '../../constants';
import { Location } from '../../models';

import './style.scss';

const MedicalPage: React.FC = () => {
  const history = useHistory();
  const [helpLocation, setHelpLocation] = useState<keyof typeof Location>();

  const isDisabled = !helpLocation;

  const goToPage = (e: React.MouseEvent) => {
    e.stopPropagation();
    history.push(CONTACTS, { medical: true, helpLocation });
  };

  return (
    <IonPage id="medical-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Medical Treatment</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Select location</IonLabel>
                <IonSelect
                  value={helpLocation}
                  onIonChange={e => setHelpLocation(e.detail.value)}
                >
                  {Object.entries(Location).map(([key, value]) => (
                    <IonSelectOption key={key} value={key}>
                      {value}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonButton
                size="large"
                type="submit"
                expand="block"
                className="custom-button-margin-top"
                disabled={isDisabled}
                onClick={goToPage}
              >
                Get Medical Help
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MedicalPage;

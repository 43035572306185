import { IonButton, IonCard, IonCardContent } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { PLAYLIST } from '../../constants';
import type { Playlist } from '../../models';

import './styles.scss';

interface ProgressCardProps {
  isAward?: boolean;
  playlists: Playlist[];
  completedPlaylists: (string | null)[];
}

const ProgressCard: React.FC<ProgressCardProps> = ({
  isAward,
  playlists,
  completedPlaylists,
}) => {
  const history = useHistory();

  const requiredPlaylists = playlists.filter(playlist => playlist.mandatory);

  const completedPlaylistsCount = requiredPlaylists.filter(playlist =>
    completedPlaylists.includes(playlist.id),
  ).length;

  const progressPercent =
    (completedPlaylistsCount / requiredPlaylists.length) * 100;

  const progressPredicate = progressPercent > 0 ? 'Continue' : 'Start';

  const getNextPlaylistID = () => {
    const incompletedPlaylists = requiredPlaylists.filter(
      playlist => !completedPlaylists.includes(playlist.id),
    );
    if (incompletedPlaylists.length) return incompletedPlaylists[0].id;
  };

  const nextPlaylistID = getNextPlaylistID();

  const goToPage = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(PLAYLIST, { playlistID: nextPlaylistID });
  };

  const renderCardContent = () => {
    if (nextPlaylistID) {
      return (
        <IonCardContent>
          <div>
            {isAward ? (
              <>
                <h3>
                  My progress - {completedPlaylistsCount}{' '}
                  {completedPlaylistsCount !== 1 ? 'Awards' : 'Award'}!
                </h3>
                <p>
                  <a href="#1" onClick={goToPage}>
                    Keep watching
                  </a>{' '}
                  to unlock more awards.
                </p>
              </>
            ) : (
              <>
                <h3>
                  {completedPlaylistsCount} of {requiredPlaylists.length}{' '}
                  playlists completed
                </h3>
                <p>Tap the button to {progressPredicate}.</p>
              </>
            )}
          </div>
          {!isAward && (
            <IonButton onClick={goToPage}>{progressPredicate}</IonButton>
          )}
        </IonCardContent>
      );
    }
    return (
      <IonCardContent>
        <div>
          {isAward ? (
            <h3>My progress - {completedPlaylistsCount} Awards</h3>
          ) : (
            <h3>My progress - {progressPercent}%</h3>
          )}
          <p>Congratulations!</p>
        </div>
      </IonCardContent>
    );
  };

  return (
    <div id="progress-card">
      <IonCard>
        <div
          className="progress-overlay"
          style={{ width: `${progressPercent}%` }}
        />
        {renderCardContent()}
      </IonCard>
    </div>
  );
};

export default ProgressCard;

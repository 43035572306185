import { IonImg } from '@ionic/react';
import { memo, useEffect, useState } from 'react';

import RootImage from '../../assets/images/gainfull_blue_enough_full.png';
import './style.scss';

const SpashScreen: React.FC = () => {
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(true);

  useEffect(() => {
    const splashScreen = setTimeout(() => setShowSplashScreen(false), 4000);
    return () => {
      clearTimeout(splashScreen);
    };
  }, []);

  if (!showSplashScreen) return null;

  return (
    <div id="splash-screen">
      <IonImg className="ion-padding-vertical" src={RootImage} />
    </div>
  );
};

export default memo(SpashScreen);

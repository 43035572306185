import { AmplifyS3Image } from '@aws-amplify/ui-react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from '@ionic/react';
import { thumbsUpOutline, thumbsUp } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { PLAYLIST } from '../../constants';
import type { Playlist } from '../../models';
import './styles.scss';

interface PlaylistCardProps {
  playlist: Playlist;
  completed: boolean;
}

const PlaylistCard: React.FC<PlaylistCardProps> = ({ playlist, completed }) => {
  const history = useHistory();
  const { id, image, title, duration, videoCount } = playlist;

  const goToPlaylist = (e: React.MouseEvent) => {
    e.stopPropagation();
    history.push(PLAYLIST, { playlistID: id });
  };

  return (
    <div id="playlist-card">
      <IonCard onClick={goToPlaylist}>
        <AmplifyS3Image imgKey={image} />
        <IonCardHeader>
          <IonCardSubtitle color="primary">{videoCount} videos</IonCardSubtitle>
          <IonCardTitle>{title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <span>{duration}</span>
          <IonIcon
            color={completed ? 'primary' : ''}
            icon={completed ? thumbsUp : thumbsUpOutline}
          />
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default PlaylistCard;

import {
  IonCard,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from '@ionic/react';
import { caretDown, caretUp } from 'ionicons/icons';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import './styles.scss';

interface AccordionProps {
  title: string;
  description?: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div id="accordion">
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonCard onClick={() => setIsOpen(!isOpen)}>
              <IonCardTitle>{title}</IonCardTitle>
              <IonIcon src={isOpen ? caretUp : caretDown}></IonIcon>
            </IonCard>
            <IonGrid>
              {isOpen && (
                <IonRow>
                  <IonCol>
                    <ReactMarkdown>{description || ''}</ReactMarkdown>
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Accordion;

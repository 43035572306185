import { AmplifyS3Image } from '@aws-amplify/ui-react';
import { IonIcon, IonImg, IonAvatar, IonLabel, IonItem } from '@ionic/react';
import { callOutline, mailOutline } from 'ionicons/icons';
import React, { useState } from 'react';

import AvatarImage from '../../assets/images/avatar.png';
import type { Contact } from '../../models';
import ContactModal from '../ContactModal';

import './style.scss';

interface ContactItemProps {
  contact: Contact;
}

const ContactItem: React.FC<ContactItemProps> = ({ contact }) => {
  const { title, image, name, phone, email } = contact;
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleModal = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(!showModal);
  };

  return (
    <div id="contact-item">
      <IonItem>
        <IonAvatar slot="start" onClick={toggleModal}>
          {image ? (
            <AmplifyS3Image imgKey={image} />
          ) : (
            <IonImg src={AvatarImage} />
          )}
        </IonAvatar>
        <IonLabel
          style={{ whiteSpace: 'unset', overflow: 'unset' }}
          onClick={toggleModal}
        >
          <strong>{name}</strong>
          <span>{title}</span>
        </IonLabel>
        <a href={`tel:${phone}`}>
          <IonIcon color="secondary" icon={callOutline} />
        </a>
        <a href={`mailto:${email}`}>
          <IonIcon color="secondary" icon={mailOutline} />
        </a>
      </IonItem>
      {showModal && (
        <ContactModal
          contact={contact}
          onDidDismiss={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ContactItem;

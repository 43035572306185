import { AmplifyS3Image } from '@aws-amplify/ui-react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Vimeo from '@u-wave/react-vimeo';
import React, { memo } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import type { Explore } from '../../models';
import './style.scss';

type ExploreDetailsProps = RouteComponentProps<any, any, { explore: Explore }>;

const ExploreDetailsPage: React.FC<ExploreDetailsProps> = ({ location }) => {
  const { explore } = location.state || {};
  const {
    Video: video,
    Graphic: graphic,
    Infographic: infographic,
  } = explore || {};

  const renderExplore = () => {
    if (video) {
      const { englishUrl } = video;
      return <Vimeo responsive video={englishUrl || ''} />;
    }
    if (graphic) {
      const { image } = graphic;
      return <AmplifyS3Image imgKey={image} />;
    }
    if (infographic) {
      const { image, description } = infographic;
      return (
        <>
          <AmplifyS3Image imgKey={image} />
          <p>{description}</p>
        </>
      );
    }
  };

  return (
    <IonPage id="explore-details-page">
      <IonHeader>
        <IonToolbar>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>{explore?.title}</IonTitle>
          </IonToolbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>{renderExplore()}</IonContent>
    </IonPage>
  );
};

export default memo(ExploreDetailsPage);

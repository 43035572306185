import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  useIonToast,
} from '@ionic/react';
import { Auth } from 'aws-amplify';
import React, { memo, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { CONFIRM, LOGIN } from '../../constants';
import { AuthContext, setCurrentUserAttributes } from '../../reducers/auth';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

const SignUpPage: React.FC = () => {
  const history = useHistory();
  const [present, dismiss] = useIonToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { authDispatch } = useContext(AuthContext);

  const isDisabled = !email || !password || !confirmPassword || isLoading;

  const signUp = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password === confirmPassword) {
      setIsLoading(true);
      try {
        await Auth.signUp({
          username: email,
          password,
        });
        authDispatch(setCurrentUserAttributes({ email }));
        history.replace(CONFIRM);
      } catch (error: any) {
        console.log('error signing up:', error);
        setIsLoading(false);
        present(
          getToastParams(
            {
              message: error.message,
            },
            dismiss,
          ),
        );
      }
    } else {
      present(
        getToastParams(
          {
            message: "Confirm Password doesn't match.",
          },
          dismiss,
        ),
      );
    }
  };

  return (
    <IonPage id="signUp-page">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText className="auth-pages-text">
                <h1>Welcome,</h1>
                <p>You can sign up using your university email address</p>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <form onSubmit={signUp}>
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput
                    required
                    type="email"
                    pattern="[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{1,}([.][A-Za-z0-9._%+-]{1,})"
                    value={email}
                    onIonChange={e => setEmail(e.detail.value || '')}
                  />
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">Create Password</IonLabel>
                  <IonInput
                    required
                    type="password"
                    value={password}
                    onIonChange={e => setPassword(e.detail.value || '')}
                  />
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">Confirm Password</IonLabel>
                  <IonInput
                    required
                    type="password"
                    value={confirmPassword}
                    onIonChange={e => setConfirmPassword(e.detail.value || '')}
                  />
                </IonItem>
                <IonButton
                  size="large"
                  type="submit"
                  expand="block"
                  className="custom-button-margin-top"
                  disabled={isDisabled}
                >
                  Register
                </IonButton>
                <IonText className="ion-text-center">
                  <p>
                    Already registered? <Link to={LOGIN}>Login</Link>
                  </p>
                </IonText>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(SignUpPage);

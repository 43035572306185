import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';

import type { Playlist } from '../../models';
import AwardCard from '../AwardCard';
import CustomCarousel from '../CustomCarousel';

import './styles.scss';

interface AwardsCarouselProps {
  title?: string;
  playlists: Playlist[];
  completedPlaylists: (string | null)[];
}

const AwardsCarousel: React.FC<AwardsCarouselProps> = ({
  title,
  playlists,
  completedPlaylists,
}) => {
  if (!playlists.length) return null;

  return (
    <div id="awards-carousel">
      <IonGrid>
        {title && (
          <IonRow>
            <IonCol>
              <h2>{title}</h2>
            </IonCol>
          </IonRow>
        )}
        <IonRow className="awards-cards">
          <IonCol>
            <CustomCarousel slidesLength={playlists.length}>
              {playlists.map(
                ({ id, Award: award }) =>
                  award && (
                    <AwardCard
                      key={id}
                      playlistID={id}
                      award={award}
                      completed={completedPlaylists.includes(id)}
                    />
                  ),
              )}
            </CustomCarousel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default AwardsCarousel;

import { DataStore, SortDirection } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PLAYLIST, QUIZ, SURVEY } from '../constants';
import { Content } from '../models';

export type GoToPageType = (content: Content) => void;

type useNextContentReturnType = {
  goToPage: GoToPageType;
  getNextContent: (currentContentID: string) => Content | undefined;
};

export const useNextContent = (
  playlistID: string,
): useNextContentReturnType => {
  const history = useHistory();
  const [contents, setContents] = useState<Content[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await DataStore.query(
          Content,
          c => c.playlistID('eq', playlistID),
          {
            sort: s => s.order(SortDirection.ASCENDING),
          },
        );
        setContents(data);
      } catch (error) {
        console.log('error fetch content:', error);
      }
    })();
  }, [playlistID]);

  const getNextContent = (currentContentID: string) => {
    const currentContentIndex = contents.findIndex(
      content => content.id === currentContentID,
    );
    if (currentContentIndex < contents.length) {
      const nextIndex = currentContentIndex + 1;
      const nextContent = contents[nextIndex];
      return nextContent;
    }
  };

  const goToPage = (content: Content) => {
    const { Video: video, Quiz: quiz, Survey: survey } = content;
    if (video) {
      history.replace(PLAYLIST, {
        video,
        playlistID,
        contentID: content.id,
      });
    } else if (quiz) {
      history.push(QUIZ, {
        quiz,
        playlistID,
        contentID: content.id,
      });
    } else if (survey) {
      history.push(SURVEY, {
        survey,
        playlistID,
        contentID: content.id,
      });
    }
  };

  return { goToPage, getNextContent };
};

import {
  IonButton,
  IonSelect,
  IonSelectOption,
  IonContent,
  IonGrid,
  IonItem,
  IonToolbar,
  IonPage,
  IonRow,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonLabel,
  IonTextarea,
  IonCheckbox,
  IonCol,
  IonLoading,
  useIonToast,
} from '@ionic/react';
import { DataStore } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../reducers/auth';
import { CHAT } from '../../constants';
import { Category, Location, Report } from '../../models';
import { StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';
import './style.scss';

const ReportPage: React.FC = () => {
  const { currentUserAttributes } = useContext(AuthContext);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="parent">
          <div className="child">
            <p>Do you need support?</p>
            <p>
              Reach out to{' '}
              <a href={`mailTo:${currentUserAttributes.contact}`}>
                {currentUserAttributes.contact}
              </a>{' '}
              for help.
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ReportPage;

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { DataStore } from 'aws-amplify';
import React, { memo, useContext, useEffect, useState } from 'react';

import ExploresCarousel from '../../components/ExploresCarousel';
import { Explore, UniversityExplore, Category } from '../../models';
import { StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

const ExplorePage: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const { student } = useContext(StudentContext);
  const [explores, setExplores] = useState<Explore[]>([]);
  const { universityID } = student || {};
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;
    if (universityID) {
      (async () => {
        try {
          const exploresIDs = (await DataStore.query(UniversityExplore))
            .filter(ue => ue.university.id === universityID)
            .map(ue => ue.explore.id);

          const data = (await DataStore.query(Explore)).filter(e =>
            exploresIDs.includes(e.id),
          );

          if (isMounted) setExplores(data);
        } catch (error: any) {
          console.log('error fetch explore:', error);
          present(
            getToastParams(
              {
                message: error.message,
              },
              dismiss,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      })();
      return () => {
        isMounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityID]);

  const capitalize = (s: string) => {
    const lower = s.toLowerCase();
    return lower[0].toUpperCase() + lower.slice(1);
  };

  return (
    <IonPage id="explore-page">
      <IonHeader>
        <IonToolbar>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>Explore</IonTitle>
          </IonToolbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {Object.keys(Category).map((category, index) => (
          <ExploresCarousel
            key={index}
            title={capitalize(category)}
            explores={explores.filter(e => e.category === category)}
          />
        ))}
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(ExplorePage);

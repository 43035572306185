import {
  IonContent,
  IonToolbar,
  IonTitle,
  IonHeader,
  IonText,
  IonModal,
  IonButton,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import './style.scss';
import { DataStore } from 'aws-amplify';
import { arrowDownCircle, arrowUpCircle } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';

import { Student, University } from '../../models';
import { setStudentData, StudentContext } from '../../reducers/student';

const TermsAndConditions: React.FC = () => {
  const { student, studentDispatch } = useContext(StudentContext);
  const { id: studentID = '', universityID } = student || {};
  const [university, setUniversity] = useState<University>();
  const [newStudentData, setNewStudent] = useState<Student>();
  const [state, setstate] = useState({
    showUserAgreement: false,
    showPrivacyPolicy: false,
    showTerms: false,
    showTrigger: false,
    showPersonalInformation: false,
    showDisclaimer: false,
    showCopyRight: false,
  });
  const showContent = (name: any) => {
    switch (name) {
      case 'showUserAgreement':
        setstate({ ...state, showUserAgreement: !state.showUserAgreement });
        break;
      case 'showPersonalInformation':
        setstate({
          ...state,
          showPersonalInformation: !state.showPersonalInformation,
        });
        break;
      case 'showDisclaimer':
        setstate({ ...state, showDisclaimer: !state.showDisclaimer });
        break;
      case 'showCopyRight':
        setstate({ ...state, showCopyRight: !state.showCopyRight });
        break;
      case 'showPrivacyPolicy':
        setstate({ ...state, showPrivacyPolicy: !state.showPrivacyPolicy });
        break;
      case 'showTerms':
        setstate({ ...state, showTerms: !state.showTerms });
        break;
      case 'showTrigger':
        setstate({ ...state, showTrigger: !state.showTrigger });
        break;
    }
  };

  useEffect(() => {
    if (newStudentData) {
      studentDispatch(setStudentData(newStudentData));
    }
  }, [newStudentData, studentDispatch]);

  useEffect(() => {
    (async () => {
      if (universityID) {
        const university = await DataStore.query(University, universityID);
        setUniversity(university);
      }
    })();
  }, [universityID]);

  const acceptTerms = async () => {
    try {
      const original = await DataStore.query(Student, studentID);
      if (original) {
        setNewStudent(
          await DataStore.save(
            Student.copyOf(original, updated => {
              updated.acceptedTerms = true;
            }),
          ),
        );
      }
    } catch (error) {
      console.log('error updateAcceptedTerms:', error);
    }
  };

  return (
    <IonModal isOpen cssClass="terms-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Terms and Conditions</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {university?.disclaimerText ||
        university?.personalInfoStatement ||
        university?.copyrightText ? (
          <div>
            <IonLabel>
              <strong className="term1">
                {university?.name === 'The University of Hong Kong Staff' ? (
                  <strong className="term1">
                    1. Online Training Module on Preventing Sexual Harassment on
                    Campus
                  </strong>
                ) : (
                  <strong className="term1"> 1. {university?.name}</strong>
                )}
              </strong>
            </IonLabel>
            {university?.personalInfoStatement && (
              <p onClick={() => showContent('showPersonalInformation')}>
                <strong className="terms-list">
                  {university?.termslabel ? (
                    university?.termslabel
                  ) : (
                    <strong className="terms-list">
                      Personal Information Collection Statement{' '}
                    </strong>
                  )}
                  <IonIcon
                    icon={
                      state.showPersonalInformation
                        ? arrowUpCircle
                        : arrowDownCircle
                    }
                  />{' '}
                </strong>
              </p>
            )}
            {state.showPersonalInformation && (
              <IonText>
                <span
                  dangerouslySetInnerHTML={{
                    __html: university?.personalInfoStatement,
                  }}
                ></span>
              </IonText>
            )}
            {university?.disclaimerText && (
              <p onClick={() => showContent('showDisclaimer')}>
                <strong className="terms-list">
                  Disclaimer{' '}
                  <IonIcon
                    icon={
                      state.showDisclaimer ? arrowUpCircle : arrowDownCircle
                    }
                  />{' '}
                </strong>
              </p>
            )}
            {state.showDisclaimer && (
              <IonText>
                <span
                  dangerouslySetInnerHTML={{
                    __html: university?.disclaimerText,
                  }}
                ></span>
              </IonText>
            )}
            {university?.copyrightText && (
              <p onClick={() => showContent('showCopyRight')}>
                <strong className="terms-list">
                  Copyright{' '}
                  <IonIcon
                    icon={state.showCopyRight ? arrowUpCircle : arrowDownCircle}
                  />{' '}
                </strong>
              </p>
            )}
            {state.showCopyRight && (
              <IonText>
                <span
                  dangerouslySetInnerHTML={{
                    __html: university?.copyrightText,
                  }}
                ></span>
              </IonText>
            )}
          </div>
        ) : null}

        <IonLabel>
          <strong style={{ marginTop: '40px' }}>
            {' '}
            {university?.personalInfoStatement ||
            university?.disclaimerText ||
            university?.copyrightText ? (
              <strong>2. Gainfull App Terms</strong>
            ) : (
              <strong>1. Gainfull App Terms</strong>
            )}
          </strong>
        </IonLabel>
        <p onClick={() => showContent('showUserAgreement')}>
          <strong className="terms-list">
            User Agreements{' '}
            <IonIcon
              icon={state.showUserAgreement ? arrowUpCircle : arrowDownCircle}
            />{' '}
          </strong>
        </p>
        {state.showUserAgreement && (
          <IonText>
            <p>
              <strong>
                Gainfull&trade; Mobile App License Agreement and Terms and
                Conditions
              </strong>
              <span>&nbsp;</span>
            </p>
            <p>
              <span>Effective as of 2/1/2022</span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <strong>
                  <strong>INTRODUCTION</strong>
                </strong>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <strong>
                Please read this License Agreement and Terms and Conditions
                carefully before using the Gainfull&trade; Mobile App.
              </strong>
            </p>
            <p>
              <span>
                Welcome to the Gainfull&trade; Mobile App (the &ldquo;
              </span>
              <strong>Mobile App</strong>
              <span>
                &rdquo;), which is owned and operated by Blue Seat Studios, a
                Rhode Island limited liability company with a mailing address of
                4 Stonecarry Way, East Greenwich, RI 02818,, on behalf of itself
                and its subsidiaries and affiliates (collectively, &ldquo;
              </span>
              <strong>We</strong>
              <span>&rdquo;). Gainfull&trade; </span>
              <span>
                is a proprietary web-based website and mobile app that relates
                to sexual assault prevention, healthy relationships, and other
                related topics through which users may interact through
                smartphones and other devices and through related website(s)
                (the &ldquo;
              </span>
              <strong>Website</strong>
              <span>
                &rdquo;), and is being provided to you as a current student at
                your institution (the &ldquo;
              </span>
              <strong>University</strong>
              <span>&rdquo;).</span>
            </p>
            <p>
              <span>
                The Mobile App may track and report your access, use, and/or
                interactions with the Mobile App and/or your interactions with
                the Mobile App, including but not limited to the results of
                assessments connected with the Mobile App.&nbsp; The following
                license agreement and terms and conditions (together with the
                Program Terms and Conditions (defined below), the &ldquo;
              </span>
              <strong>Terms and Conditions</strong>
              <span>
                &rdquo;) govern your access, use, and interactions with the
                Mobile App. The Terms and Conditions, including but not limited
                to all disclaimers and limits of liability in such terms, are
                part of, and are subject to, the terms and conditions for the
                access, use, and/or interaction with the Mobile App by you
                and/or any other person who accesses, uses, and/or interacts
                with the Mobile App through you. You will also find the Privacy
                Statement referenced in the Terms and Conditions. The Terms and
                Conditions are a binding legal agreement, and by accessing,
                using, and/or interacting with the Mobile App, you agree to be
                bound by these Terms and Conditions.
              </span>
            </p>
            <p>
              <span>The Program Terms and Conditions (the &ldquo;</span>
              <strong>Program Terms and Conditions</strong>
              <span>&rdquo;) are available by scrolling down</span>
            </p>
            <p>
              <span>
                Gainfull&trade; Program Terms and Conditions, which relate to
                the specific version of Gainfull&trade; being accessed by the
                relevant user]
              </span>
            </p>
            <p>
              <span>The </span>
              <span>Owner&rsquo;s Mobile App Privacy Statement</span>
              <span> (the &ldquo;</span>
              <strong>Privacy Statement</strong>
              <span>&rdquo;) is available by scrolling down.</span>
            </p>
            <p>
              <span>
                For purposes of the Terms and Conditions, references to the
                &ldquo;Web Site&rdquo; in the Terms and Conditions shall include
                but not be limited to the Mobile App.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <strong>
                  <strong>
                    YOUR ACCESS, USE, AND/OR INTERACTION WITH THE MOBILE APP
                  </strong>
                </strong>
              </li>
            </ul>
            <ul>
              <li>
                <em>
                  <span>License</span>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                Upon and subject to your acceptance of the Terms and Conditions,
                Blue Seat Studios (the &ldquo;
              </span>
              <strong>Owner</strong>
              <span>
                &rdquo;) hereby grants you a non-exclusive, personal,
                non-transferable, non-sublicensable, limited, revocable license
                to install the Mobile App on one or more mobile devices that are
                owned by you or are at all times under your control, and access,
                use, and/or interact with the Mobile App, subject to your
                compliance with the Terms and Conditions and solely in
                connection with your participation in the educational program
                through which you are allowed access to the Mobile App. You
                hereby acknowledge and agree that the Owner has and will retain
                all right, title, interest and ownership in and to the Mobile
                App (and all modifications and derivative works thereof),
                including but not limited to any trademarks, copyrights, patents
                and trade secrets and other intellectual property and
                proprietary rights with respect thereto or contained therein.
                The Owner and its licensors reserve all rights and licenses not
                expressly granted to you herein. You will not sell, resell,
                transfer, copy, translate, publish, create derivative works of,
                make any commercial use of, modify, reverse engineer, decompile,
                or disassemble the Mobile App. The Owner may revoke this license
                at any time with or without notice to any person.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>General</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                To access, use, and/or interact with the Mobile App, you must
                have an account with your institution (the &ldquo;
              </span>
              <strong>Institution</strong>
              <span>
                &rdquo;). By accessing, using, or interacting with the Mobile
                App, you agree to: (a)&nbsp;accurately furnish all contact and
                other information requested by the Owner and notify the Owner
                immediately of any change in the information; (b)&nbsp;not
                falsify nor attempt to falsify your information, including but
                not limited to your location information or check-in or
                check-out times; (c)&nbsp;access, use, and interact with the
                Mobile App for your own personal purposes only, and not for rent
                or for hire and not for any other person other than yourself;
                (d)&nbsp;not access, use, or interact with the Mobile App in
                violation of any laws, regulation or court order, or for any
                unlawful or abusive purpose; (e)&nbsp;not access, use, or
                interact with the Mobile App in any manner unintended by the
                Owner, the Mobile App, or the University; and (f)&nbsp;comply
                with any other reasonable requirements or restrictions
                (including but not limited to the Program Terms and Conditions)
                requested or imposed at any time or from time to time by the
                Owner, the Mobile App, or the University.
              </span>
            </p>
            <p>
              <span>
                When choosing to access, use, or interact with the Mobile Act:
              </span>
            </p>
            <ul>
              <li>
                <strong>YOU AGREE</strong>
                <span> to be bound by all Terms and Conditions and the </span>
                <a href="https://www.activeandfit.com/Home/Privacy.aspx">
                  <span>Privacy Statement</span>
                </a>
                <span>.</span>
              </li>
              <li>
                <strong>YOU AGREE</strong>
                <span>
                  {' '}
                  that the information that we collect as part of the Mobile App
                  interaction and through the Mobile App&rsquo;s Website may be
                  shared with the University and with other third parties in
                  accordance with the{' '}
                </span>
                <a href="https://www.activeandfit.com/Home/Privacy.aspx">
                  <span>Privacy Statement</span>
                </a>
                <span>.</span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>Data Usage</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                To provide you with the Mobile App&rsquo;s services, the Mobile
                App uses your mobile data connection, so you must have an
                appropriate data plan from your mobile service carrier to
                access, use, or interact with the Mobile App. Please consult
                your mobile service carrier&rsquo;s pricing plan terms and
                policies to determine applicable data charges, allotments and
                limitations, restrictions such as permissible uses, and other
                terms and conditions of service. The Owner is not responsible
                for any overage or other charges or actions that your mobile
                service carrier may take against you for using data or the
                network in violation of your mobile service carrier&rsquo;s
                terms and policies. If you wish to avoid data charges from your
                mobile service carrier relating to the Mobile App, stop using
                and uninstall the Mobile App on your mobile device.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>Location-Based Services and Activity Tracking</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                If you access, use, or interact with the Mobile App, your
                location information may be submitted to the Owner. You
                understand and acknowledge that your environment and mobile
                device&rsquo;s settings may limit the Mobile App&rsquo;s ability
                to use location-based information and that this may affect the
                functionality of the Mobile App. You hereby consent to the Owner
                using your location and other needed enabling information as
                described in the Privacy Statement.
              </span>
            </p>
            <p>
              <span>
                Your activity information relating to your access, use, and/or
                interaction with the Mobile App may be transmitted through the
                Mobile App to the Owner, the University, and/or to a third-party
                data aggregator. You hereby agree to this gathering of data and
                to the use or this information as described in the Privacy
                Statement.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>Prohibited Uses</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                In addition to the limitations set forth herein and in the
                Program Terms and Conditions, you are prohibited from using the
                Mobile App in any way that:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  violates or infringes the rights of anyone else, including,
                  for example, another person&rsquo;s right to privacy;&nbsp;
                </span>
              </li>
              <li>
                <span>
                  impersonates any person, business, entity, or IP address
                  (e.g., IP spoofing);&nbsp;
                </span>
              </li>
              <li>
                <span>
                  gains or tries to gain unauthorized access to the Mobile App,
                  its computers and networks, or its user data, or that
                  otherwise modifies or interferes with the use or operation of
                  the Mobile App;&nbsp;
                </span>
              </li>
              <li>
                <span>
                  gains or tries to gain unauthorized access to the Mobile App
                  software, including but not limited to by decompiling,
                  disassembling, or reverse engineering the Mobile App;&nbsp;
                </span>
              </li>
              <li>
                <span>
                  imposes an unreasonable or disproportionately large load on
                  the Owner&rsquo;s, its affiliate&rsquo;s or its business
                  partner&rsquo;s infrastructure, including but not limited to
                  transmitting spam or using other unsolicited mass emailing
                  techniques;&nbsp;
                </span>
              </li>
              <li>
                <span>
                  alters, damages, or deletes any content provided by the Owner
                  or provided through the Mobile App;&nbsp;
                </span>
              </li>
              <li>
                <span>
                  contains computer viruses or other disruptive, damaging, or
                  harmful files or programs; or&nbsp;
                </span>
              </li>
              <li>
                <span>
                  otherwise violates the Terms and Conditions or any other
                  guidelines or policies of the Owner or the University,
                  including but not limited to the Program Terms and Conditions
                  and the Privacy Statement.&nbsp;
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>Updates and Changes</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                The Owner may update, change, add, or remove features from, or
                discontinue, the Mobile App at any time without providing you
                notice and without having liability to you or any other person
                or entity for any reason whatsoever.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <strong>
                  <strong>SERVICE LIMITATIONS AND DISCLAIMERS</strong>
                </strong>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                In addition to the disclaimers and limitations included in the
                Program Terms and Conditions, the following limitations and
                disclaimers also apply to the Mobile App:&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>General Limitations</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                Because the Mobile App communicates with the Owner, the
                University, and/or to a third-party data gatherer by
                transmitting information wirelessly and over the Internet, the
                availability of the Mobile App is subject to certain
                limitations, including but not limited to the following:
                (a)&nbsp;the Mobile App will only be available to you after you
                have registered, and only so long as you remain registered, with
                the University, and have appropriately logged into the Mobile
                App; (b)&nbsp;the Mobile App is only available in the United
                States; and (c)&nbsp;the Mobile App is not available when the
                Internet or your mobile connection is not working, when your
                mobile device settings have location-based services disabled, or
                if there are other issues with the Mobile App connection.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>Accuracy of Results</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                The Owner does not guarantee any results or the accuracy of any
                results that you may obtain from the Mobile App, including but
                not limited to location information or the calculation or other
                results of assessments connected with the Mobile App. In
                addition, you agree that the Mobile App is subject to
                limitations and restrictions outside of the Owner&rsquo;s
                control, which include but are not limited to such things as
                Internet availability, environmental conditions (such as
                buildings, weather, geography, and topography), atmospheric
                conditions, and other factors associated with the use of the
                Internet or with satellites and satellite data.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>Stored Data</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                The Owner stores certain data on and/or about your access,
                usage, or other interactions with the Mobile App, including
                without limitation the amount of time you interact with the
                Mobile App and the results of assessments. Such data stored as
                part of the Mobile App, transmitted over the Owner networks, or
                stored by the Owner, the University, and/or its service
                providers or plan sponsors may be deleted, modified, or damaged,
                and the Owner is not responsible for any such deletions,
                modifications, or damage whatsoever, or for any release of such
                information except where such release is the direct results of
                the knowing and willful misconduct of the Owner.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>Transported Data</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                There is always risk associated with transmitting information
                over the Internet, including but not limited to information
                relating to your access, use, and/or interaction with the Mobile
                App. You understand that when you access, use, and/or interact
                with the Mobile App, your communications may be intercepted by
                others, and further that any applications connected to the
                Internet are subject to inherent risks common to the Internet,
                such as hackers, viruses, etc. For these reasons, you agree that
                the Owner cannot and does not guarantee the security and privacy
                of mobile transmissions or transmissions via the Internet. You
                agree that the Owner will not be liable to any person whatsoever
                for any lack of security relating to your access, use, and/or
                interactions with the Mobile App, and that you nor any person
                accessing, using, or interacting with the Mobile App through you
                will not attempt to hold the Owner liable for any damages
                resulting from any loss of privacy or security occurring in
                connection with any communications over such networks, except
                and only to the extent where expressly prohibited by law.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <em>
                  <em>
                    <span>Other</span>
                  </em>
                </em>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>The Mobile App is provided on an &ldquo;</span>
              <strong>as is, as available</strong>
              <span>
                &rdquo; basis. Neither the Owner, any of its agents, employees,
                or contractors, or any other party involved in creating,
                producing, delivering, or monitoring the Mobile App, shall be
                liable for any access, use, or interaction with the Mobile App.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <strong>
                  <strong>SUSPENSION AND TERMINATION&nbsp;</strong>
                </strong>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                The Owner may suspend or terminate your access to all or any
                part of the Mobile App at any time and for any or no reason,
                including but not limited to for violation of any of the Terms
                and Conditions. In the event of termination, you are no longer
                authorized to access, use, or interact with the Mobile App, and
                you shall have no recourse for, and the Owner shall have no
                liability as a result of, any such termination. All provisions
                of the Terms and Conditions relating to warranties, indemnities,
                confidentiality obligations, proprietary rights, and limitation
                of liability shall remain effective despite such termination.
              </span>
            </p>
            <p>&nbsp;</p>
            <ul>
              <li>
                <strong>
                  <strong>CONTACT US</strong>
                </strong>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <span>
                Any questions regarding the Terms and Conditions should be
                directed as follows:&nbsp;
              </span>
            </p>
            <p>
              <span>hello@blueseatstudios.com</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span>
                Gainfull&trade; is a trademark of Blue Seat Studios. Other
                trademarks are the property of their respective owners.
              </span>
            </p>
            <p>
              <span>
                Scroll down for TERMS AND CONDITIONS, PRIVACY STATEMENT, AND
                CONTACT INFORMATION
              </span>
            </p>
            <p>&nbsp;</p>
          </IonText>
        )}
        <p onClick={() => showContent('showPrivacyPolicy')}>
          <strong className="terms-list">
            Privacy Policy{' '}
            <IonIcon
              icon={state.showPrivacyPolicy ? arrowUpCircle : arrowDownCircle}
            />
          </strong>{' '}
        </p>
        {state.showPrivacyPolicy && (
          <IonText>
            <h1>
              <strong>Privacy Statement</strong>
            </h1>
            <p>
              <span>Effective Date: 2/1/2022</span>
            </p>
            <p>
              <span>
                (If you are a CA resident, this statement includes your
                California Privacy Rights.)
              </span>
            </p>
            <p>
              <span>
                Welcome to the Gainfull&trade; program, which is owned and
                operated by Blue Seat Studios, a Rhode Island limited liability
                company with a mailing address of 4 Stonecarry Way, East
                Greenwich, RI, on behalf of itself and its subsidiaries and
                affiliates (collectively, &ldquo;
              </span>
              <strong>We</strong>
              <span>&rdquo;). Gainfull&trade; </span>
              <span>is a proprietary web-based website </span>
              <span>(the &ldquo;</span>
              <strong>Website</strong>
              <span>&rdquo;) </span>
              <span>and mobile app (the &ldquo;</span>
              <strong>Mobile App</strong>
              <span>
                &rdquo;) that relates to sexual assault prevention, healthy
                relationships, and other related topics through which users may
                interact through smartphones and other devices, and is being
                provided to you as member of your institution (the &ldquo;
              </span>
              <strong>Institution</strong>
              <span>&rdquo;).</span>
            </p>
            <p>
              <span>
                We value you, the user of the Website and/or the Mobile App
                (&ldquo;
              </span>
              <strong>you</strong>
              <span>
                &rdquo;) and respect the privacy of your personally identifiable
                information. We are committed to using this information
                responsibly. Such information you provide to us through the
                Website and/or the Mobile App is governed by this Privacy
                Statement. This Privacy Statement informs you about the
                program&rsquo;s information practices, including but not limited
                to what personally identifiable information is collected through
                the Website and/or the Mobile App; how your personally
                identifiable information is collected; how your personally
                identifiable information may be used; and the choices you have
                about the collection and use of your personally identifiable
                information.
              </span>
            </p>
            <p>
              <span>
                This Privacy Statement, together with Use Agreement and the
                Program Terms and Conditions, links to which are provided below,
                governs your participation in, and access, use, and interaction
                with, the Website and/or the Mobile App. By using the Website,
                and/or the Mobile App, or otherwise participating in the
                Gainfull&trade; program, you accept and agree to be bound by the
                Use Agreement, and the Program Terms and Conditions, and to
                accept the terms of this Privacy Statement as being our sole
                obligation in connection with the collection, storage, and use
                of the private information accessed by or through the Website
                and/or the Mobile App.
              </span>
            </p>
            <p>
              <span>
                You must read and familiarize yourself with this Privacy
                Statement and with the provisions of the Use Agreement and the
                Program Terms and Conditions. By accessing, using, and/or
                interacting with the Website and/or the Mobile App, you
                acknowledge and consent to our collection, processing, storing,
                sharing, and using your information as described in this Privacy
                Statement. For any questions about this Privacy Statement,
                please contact us directly through any means noted at the end of
                this Privacy Statement. If information practices change, these
                changes will be posted at www.gainfull.com or we may notify
                users through direct communication using the most recent contact
                information you have provided to us in writing in accordance
                with the notice requirements set forth in the Program Terms and
                Conditions.
              </span>
            </p>
            <p>
              <strong>Information Collected</strong>
            </p>
            <p>
              <span>
                The types of information collected through the Website and/or
                the Mobile App may be considered personally identifiable
                information under Federal laws or the laws of other
                jurisdictions applicable to you. We refer to such information
                collectively as Personal Information (&ldquo;
              </span>
              <strong>PI</strong>
              <span>
                &rdquo;). Whether or not to provide PI is your choice, but
                without providing certain information you will not be able to
                access and use all tools and features of the Website and/or the
                Mobile App, and this may or may not impact the requirements of
                the program under which you have been granted access to the
                Website and/or the Mobile App.
              </span>
            </p>
            <p>
              <span>
                Sensitive PI is information related to reproductive health,
                sexually transmitted diseases, substance use disorder, gender
                dysphoria, gender affirming care, domestic violence, and mental
                health. Please do not submit sensitive PI to us, whether through
                the Website, the Mobile App, or otherwise.
              </span>
            </p>
            <p>
              <span>We may obtain information about you:</span>
            </p>
            <ul>
              <li>
                <span>
                  when you provide it to us (e.g. by contacting us through our
                  Contact Us, when you sign up for services, and when you
                  access, use, or interact with the Website and/or the Mobile
                  App),
                </span>
              </li>
              <li>
                <span>
                  when you call us to assist with registering or enrolling for
                  the program
                </span>
              </li>
              <li>
                <span>
                  from your use of our website, including but not limited to
                  using cookies,
                </span>
              </li>
              <li>
                <span>
                  from your Sponsoring Organization (e.g. Health Plan/Insurer or
                  Employer Group), and
                </span>
              </li>
              <li>
                <span>occasionally, from our Service Providers.</span>
              </li>
            </ul>
            <table>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <p>
                      <strong>Publicly available program features</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <span>&nbsp;</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Categories of Personal Information</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Source of Collection</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>How do we use?</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>How do we share?</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>Live Fitness Class Streaming</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Identifiers:</strong>
                      <span>
                        <br />
                      </span>
                      <strong>PI:</strong>
                    </p>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>Additional Sharing</strong>
                    </p>
                  </td>
                  <td colSpan={4}>
                    <p>
                      <strong>For legal purposes</strong>
                      <span>
                        , including: as reasonably necessary to comply with law
                        or legal process (including a court or government order
                        or subpoena); to detect, prevent, or otherwise address
                        fraud, security or technical issues; to enforce all or
                        any portion of this Privacy Statement, the Use
                        Agreement, or the Program Terms and Conditions, and the
                        use of the Website and/or the Mobile App; and as
                        reasonably necessary to protect our, our other
                        users&rsquo;, any other person&rsquo;s, or the
                        public&rsquo;s rights, property or safety.
                      </span>
                    </p>
                    <p>
                      <strong>During a corporate reorganization:</strong>
                      <span>
                        {' '}
                        If we or any of our affiliates are involved in a merger,
                        acquisition, financing, or sale of business or assets,
                        information collected from and about users may be
                        transferred to one or more third parties involved in
                        such transaction and, upon such transfer, the relevant
                        third-party privacy policy or policies may govern
                        further use of the information. In the event of such a
                        change, we will endeavor to notify our users of the
                        change as well as any choices our users may have
                        regarding the change.
                      </span>
                    </p>
                    <p>
                      <strong>Aggregate information:</strong>
                      <span>
                        {' '}
                        In addition, we may provide service providers, reputable
                        third-party vendors and sponsoring organizations with
                        aggregate statistics regarding user participation,
                        Website or Mobile App traffic patterns and related usage
                        Information. The information so provided will not
                        include individually identifiable information, meaning
                        we will not share your PI when sharing aggregate
                        information.
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>
                Your Gainfull&trade; program may include access to other
                products and programs. These products and programs have separate
                terms and conditions and privacy statements, and may, but may
                not, be provided by our affiliates. You should review and accept
                their relevant terms and conditions and privacy statements
                before you use them.
              </strong>
            </p>
            <p>
              <strong>
                If your information is used to access one of our affiliate
                products, the use of your information is governed by the terms
                and conditions and privacy statement of the affiliate and the
                affiliated product.
              </strong>
            </p>
            <p>
              <strong>
                If you choose not to provide your Personal Information, certain
                features of the Website and/or the Mobile App will not be
                available to you.
              </strong>
            </p>
          </IonText>
        )}
        <p onClick={() => showContent('showTerms')}>
          <strong className="terms-list">
            Terms and Conditions{' '}
            <IonIcon icon={state.showTerms ? arrowUpCircle : arrowDownCircle} />
          </strong>
        </p>
        {state.showTerms && (
          <IonText>
            <p>
              <span>
                Welcome to the Gainfull&trade; program website (the &ldquo;
              </span>
              <strong>Website</strong>
              <span>
                &rdquo;), which is owned and operated by Blue Seat Studios., a
                Rhode Island limited liability company with a mailing address of
                4 Stonecarry Way, East Greenwich, RI, on behalf of itself and
                its subsidiaries and affiliates (collectively the
                &ldquo;Owner&rdquo; or &ldquo;We&rdquo;). Gainfull&trade;{' '}
              </span>
              <span>
                is a proprietary web-based mobile app that relates to sexual
                assault prevention, healthy relationships, and other related
                topics through which users may interact with the Gainfull&trade;
                app through smartphones and other devices (the &ldquo;
              </span>
              <strong>Mobile App</strong>
              <span>
                &rdquo;), and is provided to you as a student, employee or
                participant at your institution (the &ldquo;
              </span>
              <strong>Institution</strong>
              <span>&rdquo;).</span>
            </p>
            <p>
              <strong>READ CAREFULLY.</strong>
              <span>
                {' '}
                The following terms and conditions, together with the User
                Agreement and the{' '}
              </span>
              <a href="https://www.activeandfit.com/Home/Privacy.aspx">
                <span>Privacy Statement</span>
              </a>
              <span>
                , constitute the legally binding agreement between each user
                (&ldquo;you&rdquo;) and the Owner concerning the use of the
                Website (collectively, the &ldquo;User Agreement&rdquo;).{' '}
              </span>
              <strong>
                By accessing, using, and/or interacting with the Website, you
                accept and agree to be bound by the Terms and Conditions and the
                Privacy Statement
              </strong>
              <span>.</span>
            </p>
            <p>
              <strong>IMPORTANT NOTICES AND DISCLAIMERS:</strong>
            </p>
            <ul>
              <li>
                <span>
                  THE WEBSITE IS NOT INTENDED TO PROVIDE INSTRUCTIONS IN THE
                  EVENT OF AN EMERGENCY. IF YOU BELIEVE YOUR SITUATION IS
                  DANGEROUS OR&nbsp; LIFE-THREATENING, CALL 911 OR YOUR
                  EMERGENCY MEDICAL SYSTEM IMMEDIATELY.
                </span>
              </li>
              <li>
                <span>
                  THIS USER AGREEMENT CONTAINS PROVISIONS THAT LIMIT OUR
                  LIABILITY TO YOU AND REQUIRE YOU TO RESOLVE ANY DISPUTE WITH
                  US THROUGH FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL
                  BASIS AND NOT AS PART OF ANY CLASS OR REPRESENTATIVE ACTION.
                </span>
              </li>
              <li>
                <span>Advice and Information Disclaimers:&nbsp;</span>
              </li>
              <ol>
                <li>
                  <span>
                    OUR STAFF IS NOT QUALIFIED TO GIVE MEDICAL, LEGAL, OR OTHER
                    ADVICE, OR PROVIDE ANY INFORMATION CONCERNING SEXUAL
                    HARRASSMENT, HEALTHY RELATIONSHIPS, OR ANY OTHER TOPIC
                    RELATING TO THE WEBSITE OR THE MOBILE APP. THE INFORMATION
                    ON THE WEBSITE AND THE MOBILE APP IS OF A GENERAL NATURE AND
                    IS INTENDED ONLY FOR EDUCATIONAL PURPOSES AND SHOULD NOT BE
                    RELIED ON AS MEDICAL, LEGAL OR OTHER ADVICE. THE INFORMATION
                    ON THE WEBSITE OR THE MOBILE DEVICE SHOULD NOT BE USED AS A
                    SUBSTITUTE FOR SEEKING THE ADVICE, HELP, OR GUIDANCE OF
                    MEDICAL, LEGAL, OR OTHER QUALIFIED PROFESSIONAL.
                  </span>
                </li>
                <li>
                  <span>
                    The Website and the Mobile App are in no way intended to
                    provide any medical, legal, or other advice, or otherwise to
                    be a substitute for obtaining professional advice, nor does
                    either provide professional services or evaluations.
                    Furthermore, the Website and the Mobile App are intended for
                    educational purposes only. Products and services available
                    on through Website are not insurance. The Website expressly
                    prohibits you from using information and services on the
                    Website to diagnose, treat, cure, or prevent any condition,
                    situation, or circumstance. Application or reliance on any
                    of the content, techniques, ideas, and suggestions accessed
                    through the Website is at your sole discretion and risk.
                  </span>
                </li>
                <li>
                  <span>
                    NOTHING YOU SEE OR READ OR OBTAIN FROM THE WEBSITE OR THE
                    MOBILE APP IS INTENDED TO SUBSTITUTE FOR PROFESSIONAL ADVICE
                    OR TREATMENT. The information provided on the Website does
                    not substitute for or replace services of trained
                    professionals in their fields of expertise. We recommend
                    consulting with professionals on a regular basis with
                    respect to any matter relating to physical or mental health.
                    Neither we nor any of our affiliates, licensors, content
                    providers or suppliers makes any representations or
                    warranties concerning any treatment, action or application
                    following the information provided on or through the Website
                    or the Mobile App. We, our licensors, content providers and
                    suppliers shall not be liable for any direct, indirect,
                    consequential, special, exemplary, or other damages that may
                    result from the use of the Website or the Mobile App,
                    including but not limited to any such damages with respect
                    to any economic loss, injury, illness, or death.
                  </span>
                </li>
                <li>
                  <span>
                    You should not delay or forego seeking care and/or advice
                    from a professional because of information that may be
                    referenced on or feelings that may arise because of
                    information that may be referenced on the Website or the
                    Mobile App. Only a professional can provide you with advice
                    on what is safe and effective for you.
                  </span>
                </li>
                <li>
                  <span>
                    You agree that you will not use information provided on the
                    Website or Mobile App to diagnose, treat, cure, or prevent
                    any condition.
                  </span>
                </li>
                <li>
                  <span>
                    The information contained in the Terms and Conditions and
                    the Privacy Statement has been devised generally and could
                    be affected by certain cultural, social, or language
                    differences.
                  </span>
                </li>
              </ol>
              <li>
                <strong>Program Disclaimer:</strong>
                <span>
                  {' '}
                  It is expressly noted that some topics addressed in the
                  Website or the Mobile App are not appropriate for individuals
                  who have certain mental or other health conditions.{' '}
                </span>
                <span>
                  YOU ARE STRICTLY RESPONSIBLE FOR SEEKING ADVICE FROM A
                  PROFESSIONAL PRIOR TO ACCESSING THE WEBSITE AND/OR ACCESSING,
                  USING, OR INTERACTING WITH THE MOBILE APP OR OTHERWISE
                  CONSIDERING THE ISSUES DISCUSSED, MENTIONED OR PROVIDED ON THE
                  WEBSITE OR THROUGH THE MOBILE APP.
                </span>
                <span>
                  {' '}
                  You hereby agree and understand that you may not be aware of a
                  condition from which you suffer, and therefore you should not
                  participate in the Mobile App or in anything relating to this
                  Website prior to seeking the advice of a qualified
                  professional.
                </span>
              </li>
              <li>
                <strong>General Information Disclaimer:</strong>
                <span>
                  {' '}
                  We do not make, and neither the Website nor the Mobile App
                  makes, any warranty, express or implied, or assume any legal
                  liability or responsibility for the completeness or
                  usefulness, or the accuracy or quality of any information
                  presented on the Website or through the Mobile App The
                  information on the Website and/or in the Privacy Statement is
                  subject to change without notice and cannot be guaranteed to
                  be current.
                </span>
              </li>
            </ul>
            <p>
              <strong>Third-Party Products and Services</strong>
            </p>
            <p>
              <span>
                The Website may contain information and links about products
                and/or services (a &ldquo;
              </span>
              <strong>Third-Party Products/Services&rdquo;</strong>
              <span>
                ). Information and links about Third-Party Products/Services are
                provided for your convenience and general information purposes
                only. We do not, and the Website and the Mobile App do not,
                produce, provide, sell, sponsor, endorse or warrant any
                Third-Party Product/Service. To interact with a Third-Party
                Product/Service, you must go to the website of the applicable
                third-party seller or provider (&ldquo;
              </span>
              <strong>Applicable Third-Party Seller/Provider</strong>
              <span>
                &rdquo;), and you will be dealing with the Applicable
                Third-Party Seller/Provider and not with us, the Website or the
                Mobile App. You are solely responsible for carefully review the
                Applicable Third-Party Seller/Provider&rsquo;s terms and
                conditions and privacy policy before interacting with the
                Applicable Third-Party Seller/Provider or otherwise clicking on
                any link accessible through the Website.
              </span>
            </p>
            <p>
              <span>
                You hereby acknowledge and agree that we, whether through the
                Website, the Mobile App, or otherwise, make no representation or
                warranty, express or implied, with respect to: (a)&nbsp;the
                availability, price, make, material, craftsmanship, style,
                genuineness, quality, durability, performance, warranty,
                customer service, or ethical or environmental standard of any
                Third-Party Product/Service; or (b)&nbsp;your level of
                satisfaction with any Third-Party Product/Service. You further
                acknowledge and agree that in no event shall we, the Website, or
                the Mobile App be held responsible or liable for any injury
                (including death), loss or damage of any kind incurred by you or
                any other person as a result of: (i)&nbsp;your use of (or
                inability to use) a Third-Party Product/Service; (ii)&nbsp;any
                defect or error in a Third-Party Product/Service; (iii)&nbsp;the
                failure or refusal by an Applicable Third-Party Seller/Provider
                to accept a return, to make an exchange, to issue a refund or
                credit, or to honor a warranty with respect to a Third-Party
                Product/Service; or (iv)&nbsp;any billing error, overcharge, or
                any unauthorized or fraudulent charge, by an Applicable
                Third-Party Seller/Provider. You hereby release (to the maximum
                extent permitted by applicable law) us and our affiliates and
                subsidiaries and the respective officers, directors, agents, and
                employees from any and all losses, claims, liabilities and
                damages (including but not limited to any direct, indirect,
                consequential, incidental, punitive, special or exemplary
                damages), whether known or unknown, suspected or unsuspected,
                disclosed or undisclosed, that arise from or relate to any issue
                or dissatisfaction you may have with a Third-Party
                Product/Service.
              </span>
            </p>
            <p>
              <strong>Interactive Tools</strong>
            </p>
            <p>
              <span>
                Any interactive tools, including but not limited to assessments,
                (collectively, &ldquo;
              </span>
              <strong>Interactive Tools</strong>
              <span>
                &rdquo;), provided on the Website or on the Mobile App are
                designed to provide general educational information only, and
                are not intended to provide advice of any kind to you or any
                other person. You must consult with a qualified professional for
                proper advice, counselling, and/or treatment. The Interactive
                Tools are in no way intended as, nor are a substitute for,
                qualified, expert advice, counseling, or treatment. Information
                you submit to us when using the Interactive Tools is used as
                described in the Terms and Conditions and in the Privacy
                statement.&nbsp; Your use of the Website or the Mobile App, or
                any portion thereof, serves as your consent for us to receive,
                collect, track, and otherwise use information relating to your
                access, use, and interaction with the Website and the Mobile
                App, and to use that data as we see fit in our sole and absolute
                discretion in accordance with the Privacy Statement. For
                information regarding the uses of your personal information,
                please see the{' '}
              </span>
              <a href="https://www.activeandfit.com/Home/Privacy.aspx">
                <span>Privacy Statement</span>
              </a>
              <span> in effect from time to time.</span>
            </p>
            <p>
              <strong>Limitation of Liability</strong>
            </p>
            <p>
              <span>
                BY ACCESSING, USING, OR OTHERWISE INTERACTING WITH THE WEBSITE
                AND/OR THE MOBILE APP, YOU EXPRESSLY ACKNOWLEDGE AND AGREE AS
                FOLLOWS:
              </span>
            </p>
            <p>
              <span>(I)</span>{' '}
              <span>
                THE WEBSITE AND/OR THE MOBILE APP (INCLUDING BUT NOT LIMITED TO
                ALL CONTENTS/ELEMENTS), AND ANY AND ALL INFORMATION, PRODUCTS,
                SERVICES, AND OTHER MATERIALS AVAILABLE THROUGH THE WEBSITE
                AND/OR THE MOBILE APP, ARE PROVIDED &ldquo;
              </span>
              <strong>AS IS</strong>
              <span>&rdquo; AND &ldquo;</span>
              <strong>AS AVAILABLE</strong>
              <span>
                &rdquo; WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. TO THE
                MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR
                LICENSORS/CONTENT PROVIDERS/SUPPLIERS/OTHER AFFILIATES DISCLAIM
                ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO
                WEBSITE AND/OR THE MOBILE APP (INCLUDING BUTN OT LIMITED TO ALL
                ITS CONTENTS/ELEMENTS), AND ANY AND ALL INFORMATION, PRODUCTS,
                SERVICES, AND OTHER MATERIALS AVAILABLE THROUGH THE WEBSITE
                AND/OR THE MOBILE APP, INCLUDING BUT NOT LIMITED TO IMPLIED
                WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                PURPOSE, WARRANTIES OF TITLE AND NON-INFRINGEMENT, WARRANTIES
                AGAINST COMPUTER VIRUS, AND WARRANTIES ARISING FROM COURSE OF
                DEALING OR COURSE OF PERFORMANCE. WITHOUT LIMITING THE
                GENERALITY OF THE FOREGOING, WE AND OUR LICENSORS/CONTENT
                PROVIDERS/SUPPLIERS/OTHER AFFILIATES MAKE NO REPRESENTATION OR
                WARRANTY THAT THE WEBSITE AND/OR THE MOBILE APP (INCLUDING BUT
                NOT LIMITED TO ANY OF ITS CONTENTS/ELEMENTS), OR ANY
                INFORMATION, PRODUCT, SERVICE, OR OTHER MATERIAL AVAILABLE
                THROUGH THE WEBSITE AND/OR THE MOBILE APP, WILL BE ERROR-FREE,
                COMPLETE, USEFUL, OR SECURE, OR WILL BE UNINTERRUPTED, OR WILL
                BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, OR THAT ANY
                DEFECT OR ERROR WILL BE CORRECTED. PRICE AND AVAILABILITY
                INFORMATION PERTAINING TO ANY PRODUCT OR SERVICE AVAILABLE
                THROUGH THE WEBSITE AND/OR THE MOBILE APP IS SUBJECT TO CHANGE
                WITHOUT NOTICE.
              </span>
            </p>
            <p>
              <span>(II)</span>{' '}
              <span>
                YOUR USE OF THE WEBSITE AND/OR THE MOBILE APP (INCLUDING BUT NOT
                LIMITED TO ITS CONTENTS/ELEMENTS), AND/OR ANY INFORMATION,
                PRODUCT, SERVICE, OR OTHER MATERIAL OBTAINED FROM OR THROUGH THE
                WEBSITE AND/OR THE MOBILE APP,{' '}
              </span>
              <strong>IS ENTIRELY AT YOUR OWN RISK</strong>
              <span>
                . IF YOU ARE DISSATISFIED WITH THE WEBSITE AND/OR THE MOBILE
                APP, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF
                BOTH THE WEBSITE AND THE MOBILE APP.
              </span>
            </p>
            <p>
              <span>(III)</span>{' '}
              <span>
                EXCEPT WHERE, AND ONLY TO THE EXTENT, PROHIBITED BY APPLICABLE
                LAW, IN NO EVENT SHALL WE OR ANY OF OUT LICENSORS/ CONTENT
                PROVIDERS/SUPPLIERS/OTHER AFFILIATES BE RESPONSIBLE OR LIABLE TO
                YOU OR TO ANY THIRD PARTY FOR ANY DIRECT OR INDIRECT,
                CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES OR LOSSES ARISING
                OUT OF OR RELATED TO THE WEBSITE AND/OR THE MOBILE APP
                (INCLUDING BUT NOT LIMITED TO ANY OF ITS CONTENTS/ELEMENTS), OR
                ANY INFORMATION, PRODUCT, SERVICE, OR OTHER MATERIAL OBTAINED
                FROM OR THROUGH THE WEBSITE AND/OR THE MOBILE APP, EVEN IF WE
                AND/OR RELATED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGE OR LOSS.
              </span>
            </p>
            <p>
              <strong>Passwords, Authorizations, and Information</strong>
            </p>
            <p>
              <span>
                In the event you use a password or authority to access the
                Website or the Mobile App, you may not disclose or share your
                password or your authority with any third party or use your
                password or authority for any unauthorized purpose. You are
                responsible for maintaining the confidentiality and security of
                any such password(s) and to access, use, or interact with the
                Website and/or the Mobile App only as authorized. You are also
                responsible for all activity that occurs under your password or
                authorization and should immediately notify us and the
                University of any unauthorized use of your password or
                authority. You must also ensure that you fully and effectively
                exit from your account at the end of each session. In addition,
                you must provide us and the University with accurate, true,
                current, and complete information about yourself, and you must
                promptly provide any updates necessary to keep such information
                accurate, true, current, and complete at all times. Further, you
                may be required to provide a valid email address in order to
                participate in this program. You may not impersonate another
                person or entity, and you may not use the Website and/or the
                Mobile App to harass or stalk another user or any other person.
                You agree that you will not use the Website and/or the Mobile
                App for chain letters, junk mail, &ldquo;spamming&rdquo;
                solicitations (commercial or otherwise), or bulk communication
                of any kind. We cannot and will not be liable for any loss or
                damages arising from or relating to your failure to comply with
                any of the obligations in this paragraph or of any other Term
                and Condition.
              </span>
            </p>
            <p>
              <span>
                We have the right, at any time, to immediately terminate,
                deactivate or delete a password, user account, all related
                information and files and/or access to all or any part of the
                Website and/or the Mobile App, without any notice or incurrence
                of liability. This termination may occur for any or no reason,
                including but not limited to failure to abide by the User
                Agreement, any Term or Condition, or any law regarding the use
                of the Website and/or the Mobile App and/or various products or
                services provided on or through the Website and/or the Mobile
                App.
              </span>
            </p>
            <p>
              <strong>Advertisers or Providers of Products or Services</strong>
            </p>
            <p>
              <span>
                Your correspondence or business dealings with, or participation
                in promotions of, advertisers or providers of products or
                services found on or through the Website and/or the Mobile App,
                including but not limited to payment and delivery of related
                goods or services, and any other terms, conditions, warranties,
                or representations associated with such dealings, are solely
                between you and the advertiser or provider. You agree that we
                shall not be responsible or liable for any loss or damage of any
                sort incurred as the result of any such dealings or as the
                result of the presence of such advertisers or providers on or
                through the Website and/or the Mobile App.
              </span>
            </p>
            <p>
              <strong>Links</strong>
            </p>
            <p>
              <span>
                Third-party websites linked to the Website and/or the Mobile App
                are not under our, and we are not responsible for the content of
                any third-party website or any link contained in a third-party
                website, or any changes or updates to a third-party website. We
                provide these links to you only as a convenience to you, and the
                inclusion of any link does not imply or preclude a financial or
                business relationship or endorsement by or with us of the
                third-party website or its products or services. We are not
                responsible for any activities that you may engage in on any
                third-party website, including but not limited to any purchase
                of products or services. Once you link to a third-party website,
                you will be subject to the terms and conditions of use, privacy
                rules, editorial policy or other obligations, liabilities,
                restrictions, or arrangements of or relating to that third-party
                website. Your linking to a third-party website is at your own
                risk, and we are not responsible or liable, directly or
                indirectly, for any damages or loss caused or alleged to be
                caused by or in connection with your use of or reliance on any
                content, goods or services available on or through any
                third-party website. In addition, you may not provide links to
                the Website or to the Mobile App without our express prior
                written permission, which may be withheld at our sole and
                absolute discretion.
              </span>
            </p>
            <p>
              <strong>User Submissions</strong>
            </p>
            <p>
              <span>
                Except as expressly otherwise provided in the Privacy Statement,
                any communication(s) or material(s) you transmit to or through
                the Website and/or the Mobile App whether by electronic mail or
                other medium, including but not limited to questions, comments,
                or suggestions, is and will be treated as non-confidential and
                non-proprietary information and may be used by us, our vendors,
                and/or our or their affiliates or related entities for any
                purpose, including but not limited to reproductions, disclosure,
                transmission, publication, broadcast, and postings. Furthermore,
                we, our vendors and/or our or their affiliates and related
                entities are free to use any information, ideas, concepts,
                inventions, or techniques contained in any communication you
                send to or through the Website and/or the Mobile App for any
                purpose whatsoever, including, but not limited to developing,
                manufacturing, and marketing products or services. If you wish
                to keep any information, ideas, concepts, inventions, or
                techniques private or proprietary, do not submit them to or
                through the Website or the Mobile App.
              </span>
            </p>
            <p>
              <span>
                You represent and warrant that any content or material you
                submit to us: (1)&nbsp;will not infringe any copyright, patent,
                trademark, trade secret or other proprietary rights;
                (2)&nbsp;will not violate any law, statute, ordinance or
                regulation; (3)&nbsp;will not be obscene or contain child
                pornography; (4)&nbsp;will not contain any viruses, worms, time
                bombs or other computer programming code that is intended to
                damage, detrimentally interfere with, surreptitiously intercept
                or expropriate any system, data or information; (5)&nbsp;will
                not violate any rights of publicity or privacy; and
                (6)&nbsp;will not be defamatory, unlawfully threatening or
                harassing, harmful to minors in any way, or otherwise offensive
                or inappropriate. You are responsible for complying with all
                laws relating to your submitted content or material.
              </span>
            </p>
            <p>
              <strong>Proprietary Rights</strong>
            </p>
            <p>
              <span>
                The Website and the Mobile App contain proprietary information,
                including but not limited to logos, service marks, trademarks,
                software, photos, videos, text, graphics, comments, feedback,
                ideas, templates, instructions, notes, drawings, articles and
                other materials, and the selection, assembly and arrangement of
                information (collectively, &ldquo;
              </span>
              <strong>Content</strong>
              <span>
                &rdquo;). All Content is protected by copyrights, trademarks,
                service marks and other proprietary rights that are owned by us,
                our affiliates, or by third parties that have licensed their use
                to us or our affiliates. You may NOT download material displayed
                on the Website or the Mobile App.&nbsp; Further, you may not
                use, reproduce, copy, distribute, modify, transmit, repost,
                display, publish, sell, license, create derivative works, assign
                or transfer any Content or information on the Website or on the
                Mobile App for public or commercial purposes without our express
                prior written permission, , which may be withheld at our sole
                and absolute discretion. Nothing on the Website or the Mobile
                App shall be construed as conferring any license or other rights
                under our, our affiliate&rsquo;s, or any other person&rsquo;s
                intellectual property or other proprietary rights, whether by
                estoppel, implication, or otherwise.
              </span>
            </p>
            <p>
              <span>
                Gainfull and the Gainfull logo are federally registered
                trademarks of Blue Seat Studios. You shall not display,
                disparage, dilute, or taint our marks or use any confusingly
                similar marks or use our marks in such a way that would
                misrepresent who the proper owner of such mark is. Any permitted
                use of our marks by you shall inure to our benefit. Content of
                the Website and/or through the Mobile App is copyrighted
                Copyright &copy; 2020, Blue Seat Studios or its licensors. All
                rights reserved.
              </span>
            </p>
            <p>
              <strong>Indemnification</strong>
            </p>
            <p>
              <span>
                You hereby agree to defend, indemnify, and hold harmless us and
                our affiliates, employees, officers, directors, agents,
                licensors, content providers and suppliers from and against any
                and all claims, liabilities, costs, and expenses, including but
                not limited to reasonable attorneys' fees, related to your
                violation of the User Agreement or any Term or Condition, or any
                activity by you on or through the Website or the Mobile App.
              </span>
            </p>
            <p>
              <strong>Use Website and/or Mobile App At Own Risk</strong>
            </p>
            <p>
              <span>
                THE SERVICES, PRODUCTS AND CONTENT DOWNLOADED OR OTHERWISE
                OBTAINED THROUGH THE USE OF THE WEBSITE AND/OR THE MOBILE APP
                ARE DONE AT YOUR SOLE RISK. YOU WILL BE SOLELY RESPONSIBLE FOR
                ANY DAMAGE TO YOUR COMPUTER OR OTHER SYSTEM OR LOSS OF DATA THAT
                RESULTS FROM THE DOWNLOAD OF ANY PRODUCTS OR CONTENT TO,
                THROUGH, OR IN CONNECTION WITH THE WEBSITE OR THE MOBILE APP. WE
                ARE NOT RESPONSIBLE FOR ANY TERMINATION OF YOUR CONNECTION TO
                THE WEBSITE OR THE MOBILE APP, EVEN IF SUCH TERMINATION OF
                CONNECTION AFFECTS ANY PURCHASE OR OTHER ACTIVITY.
              </span>
            </p>
            <p>
              <strong>Disputes</strong>
            </p>
            <p>
              <strong>Waiver of Right to Pursue Class Action Claims</strong>
            </p>
            <p>
              <strong>
                Unless and only to the extent prohibited by applicable law, you
                agree to only resolve disputes with us or otherwise in
                connection with the Website and/or the Mobile App on an
                individual basis and waive any right to pursue any claims on a
                class or consolidated basis or in a representative capacity.
              </strong>
              <span>
                {' '}
                YOU AND WE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
                ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
                MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR
                PROCEEDING.
              </span>
            </p>
            <p>
              <strong>
                Waiver of the Right to Seek Public Injunctive Relief
              </strong>
            </p>
            <p>
              <strong>
                You agree to waive any right to seek injunctive relief. If any
                part of this provision is found to be unenforceable, you agree
                that a claim for injunctive relief will be brought in
                arbitration pursuant to the terms herein, and that you expressly
                acknowledge you are waiving any and all rights to bring a
                injunctive relief claim in court.
              </strong>
            </p>
            <p>
              <strong>Notice of Dispute</strong>
            </p>
            <p>
              <span>
                Prior to filing a demand for arbitration, you must provide us
                with written notice and an opportunity to respond and/or we must
                provide you with written notice and an opportunity to respond.
              </span>
            </p>
            <p>
              <span>
                You must send a Notice of Dispute describing the dispute by
                certified mail to us as detailed in the Notice provision below.
                We then have 30 days from actual receipt of your Notice of
                Dispute to respond. If you deem our response unsatisfactory, you
                may file a demand for arbitration as detailed below.
              </span>
            </p>
            <p>
              <span>
                We must send a Notice of Dispute describing the dispute by
                certified mail to you as detailed in the Notice provision below.
                You then have 30 days from actual receipt of our Notice of
                Dispute to respond. If we deem your response unsatisfactory, we
                may file a demand for arbitration as detailed below.
              </span>
            </p>
            <p>
              <strong>Agreement to Arbitrate Claims</strong>
            </p>
            <p>
              <strong>
                You agree to arbitrate all disputes between you and us pursuant
                to the Federal Arbitration Act.
              </strong>
              <span>
                {' '}
                If a dispute arises between you and us, whether relating to the
                Website, the Mobile App, or any provision of the Use Agreement
                or of these Terms and Conditions, you and we agree that the
                dispute shall be resolved by final and binding arbitration
                administered by the American Arbitration Association under its
                rules for consumer arbitrations (&ldquo;
              </span>
              <strong>AAA Rules</strong>
              <span>
                &rdquo;). You and we agree to the following rules relating to
                any arbitration proceeding:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  YOU AND WE WAIVE ANY RIGHT TO BRING SUCH CLAIMS BEFORE ANY
                  COURT OF LAW. RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT,
                  SUCH AS ACCESS TO DISCOVERY, MAY BE UNAVAILABLE OR LIMITED IN
                  ARBITRATION.
                </span>
              </li>
              <li>
                <span>
                  THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF
                  AN ARBITRATION AWARD IS LIMITED.
                </span>
              </li>
              <li>
                <span>
                  The venue for all disputes shall be the state in which you
                  reside, but you and we may agree to conduct the arbitration by
                  telephone, online, and/or solely based on written submissions.
                  You hereby waive any right to claim that such location is an
                  inconvenient forum and covenant not to sue us in any other
                  forum. However, if the parties choose to conduct in-person
                  proceedings and the arbitrator determines that this location
                  imposes unreasonable burdens, the arbitrator has the power to
                  order that the proceedings take place elsewhere or by
                  telephone or online.
                </span>
              </li>
              <li>
                <span>
                  The arbitrator will have the power to grant whatever relief
                  would be available in court under law or in equity (including
                  but not limited to attorneys&rsquo; fees) and any award of the
                  arbitrator will be final and binding on each of the parties.
                </span>
              </li>
              <li>
                <span>
                  The arbitrator shall apply the applicable law and the other
                  dispute provisions of these Terms, and the failure to do so
                  shall be deemed an excess of arbitral authority and grounds
                  for judicial review.
                </span>
              </li>
              <li>
                <span>
                  Any judgment on the award rendered by the arbitrator may be
                  entered in any court of competent jurisdiction.
                </span>
              </li>
              <li>
                <span>
                  Barring extraordinary circumstances, the arbitrator will issue
                  a decision within 120 days from the date the arbitrator is
                  appointed, which may be extended 30 days in the interests of
                  justice.
                </span>
              </li>
              <li>
                <span>
                  All arbitration proceedings will be closed to the public and
                  confidential and all records relating thereto will be
                  permanently sealed, except as absolutely necessary to obtain
                  court confirmation of the arbitration award.
                </span>
              </li>
              <li>
                <span>
                  The award of the arbitrator will be in writing and will not
                  include any statement setting forth the reasons for the
                  disposition of any claim. Either you or we may request an
                  injunction at any time from any court of competent
                  jurisdiction as necessary to protect the rights or property of
                  you or us pending the completion of arbitration.
                </span>
              </li>
              <li>
                <span>
                  Either you or we may start arbitration proceedings. Any
                  arbitration will take place under AAA Rules, as modified by
                  this Arbitration Agreement. You and we agree that the Federal
                  Arbitration Act applies and governs the interpretation and
                  enforcement of this provision (despite the choice of law
                  provision above). The AAA Rules, as well as instructions on
                  how to file an arbitration proceeding with the AAA, appear at
                  adr.org, or you may call the AAA at 1-800-778-7879. We can
                  also help put you in touch with the AAA.
                </span>
              </li>
            </ul>
            <p>
              <span>
                Payment of all filing, administration and arbitrator fees will
                be governed by the AAA Rules. If, however, you are able to
                demonstrate that the costs of arbitration will be prohibitive
                for you as compared to the costs of litigation, we will pay as
                much of the filing, administration and arbitrator fees as the
                arbitrator deems necessary to prevent the arbitration from being
                cost-prohibitive for you. If the arbitrator determines that the
                claim(s) you assert in the arbitration are frivolous, you agree
                to reimburse us for all fees and, where permitted, expenses
                associated with the arbitration that we paid or incurred that
                you otherwise would be pay under the AAA Rules.
              </span>
            </p>
            <p>
              <strong>Miscellaneous</strong>
            </p>
            <p>
              <strong>Not Intended for Minors under the age of 13</strong>
            </p>
            <p>
              <span>
                Minors under the age of 13 years old are not permitted to
                access, use, or interact with the Website or the Mobile App
                without the permission and oversight of a parent or guardian.
              </span>
            </p>
            <p>
              <strong>Information Privacy</strong>
            </p>
            <p>
              <span>
                Your privacy is important to us. To learn more about how we
                protect your personally identifiable information collected on or
                through the Website or the Mobile App, please refer to the
                Privacy Statement, which exclusively governs our obligations to
                you in respect of all information collected on or through the
                Website or the Mobile App.
              </span>
            </p>
            <p>
              <strong>System Integrity</strong>
            </p>
            <p>
              <span>
                You may not use any device, software, or routine to interfere or
                attempt to interfere with the proper working of the Website or
                the Mobile App, or any transaction conducted on or through the
                Website or the Mobile App. You may not take any action that
                imposes an unreasonable or disproportionately large load on our
                infrastructure.
              </span>
            </p>
            <p>
              <strong>Assignment</strong>
            </p>
            <p>
              <span>
                We may assign the User Agreement, any Terms and Conditions, the
                Privacy Statement, or any of our rights and/or obligations under
                any of them, to any third party without your prior consent. The
                User Agreement and all Program Terms and Conditions will inure
                to the benefit of our successors, assigns, and licensees.
              </span>
            </p>
            <p>
              <strong>Electronic Communications</strong>
            </p>
            <p>
              <span>
                By using the Website or the Mobile App, you are communicating
                with us electronically, and you consent to receiving
                communications from us (or entities acting on our behalf)
                electronically or otherwise, including but not limited to emails
                and (if you have an account on the Website or through the Mobile
                App) messages posted to your account. Please note, you may be
                required to provide an email in order to participate in the
                Website and/or the Mobile App. You acknowledge and agree that
                all agreements, notices, disclosures, and other communications
                that we provides to you, whether electronically or otherwise,
                satisfy any legal requirement that the same be in writing and
                delivered to you.
              </span>
            </p>
            <p>
              <strong>Notices</strong>
            </p>
            <p>
              <span>
                All notices required or permitted by the User Agreement, these
                Program Terms and Conditions, and/or the Privacy Statement shall
                be in writing and may be delivered in person or may be sent by
                registered or certified mail to the following address:
              </span>
            </p>
            <p>
              <span>4 Stonecarry Way, East Greenwich, RI 02818</span>
            </p>
            <p>
              <span>
                All notices required or permitted to be given by us to you shall
                be in writing and may be delivered in person or may be sent to
                the most recent contact information you have provided to us in a
                writing delivered pursuant to the immediately preceding Notice
                provision.
              </span>
            </p>
            <p>
              <strong>Governing Law</strong>
            </p>
            <p>
              <span>
                This User Agreement shall be construed, governed by, and
                enforced solely and exclusively in accordance with the laws of
                the State in which you reside, without giving effect to its
                conflict of laws provisions of your actual state or country of
                residence. You hereby agree that the courts located in the State
                of Rhode Island, USA shall constitute the sole and exclusive
                forum for the resolution of any and all disputes arising out of
                or in connection with the User Agreement, these Terms and
                Conditions, and/or the Privacy Statement, and you hereby consent
                to the personal jurisdiction and venue of such courts and
                irrevocably waive any objections thereto.
              </span>
            </p>
            <p>
              <strong>Modifications</strong>
            </p>
            <p>
              <span>
                From time to time, we may update and modify all or any part of
                the User Agreement, these Terms and Conditions, the Privacy
                Statement, the Website, and/or the Mobile App, whether to
                accommodate new technology, industry practices, regulatory
                requirements, or for any other purpose. Where to the extent
                required by applicable law, we will provide you with notice if
                the changes are material and, where required by applicable law,
                we will obtain your consent. Unless expressly otherwise stated,
                all material changes will apply prospectively only. If you have
                an account on the Website or the Mobile App, you may be asked to
                affirmatively agree to the changes (e.g., by checking a box or
                clicking a button or otherwise) at the time of your next account
                login.&nbsp; If you do not agree to such changes, your access to
                the Website and/or the Mobile App will immediately terminate,
                without any notice or incurrence of liability.
              </span>
            </p>
            <p>
              <strong>Waiver</strong>
            </p>
            <p>
              <span>
                Any waiver of any provision of the User Agreement or any Term
                and Condition will be effective only if in a writing and signed
                by us. Our failure to insist upon or enforce the strict
                performance of the terms of the User Agreement or any Terms and
                Conditions, or to exercise any right under thereunder, shall not
                be considered a waiver of the right to assert or rely upon any
                such provision or right in that or any other instance.
              </span>
            </p>
            <p>
              <strong>Limitation as to Third Party Beneficiary</strong>
            </p>
            <p>
              <span>
                You acknowledge and agree that (i) the terms of the User
                Agreement and the Terms and Conditions that relate to
                arbitration, limitation on liability, disclaimers, indemnities
                and waiver and release (these sections constituting the &ldquo;
              </span>
              <strong>Specified Sections</strong>
              <span>
                &rdquo;) shall apply both with respect to us and the third party
                providers of the content that we makes available through the
                Website or the Mobile App (each a &ldquo;
              </span>
              <strong>Content Provider</strong>
              <span>
                &rdquo;); and (ii)&nbsp;each applicable Content Provider shall
                be a third party beneficiary hereunder for purposes of the
                Specified Sections, is entitled to the applicable rights and
                benefits set forth in the Specified Sections, and may enforce
                the provisions in the Specified Sections as if it were a party
                to the User Agreement and the Terms and Conditions. Except as
                stated here in, there are no other third-party beneficiaries to
                this Agreement.
              </span>
            </p>
            <p>&nbsp;</p>
          </IonText>
        )}
        <p onClick={() => showContent('showTrigger')}>
          <strong className="terms-list">
            Trigger Warning{' '}
            <IonIcon
              icon={state.showTrigger ? arrowUpCircle : arrowDownCircle}
            />
          </strong>
        </p>
        {state.showTrigger && (
          <IonText>
            <p>
              <em>
                <span>
                  This progressive web app may include readings, media,
                  discussions, and/or presentations around topics such as sexual
                  assault, domestic violence, stalking, physical violence, and
                  identity-based discrimination and harassment.&nbsp;
                </span>
              </em>
            </p>
            <p>&nbsp;</p>
            <p>
              <em>
                <span>Such content may be harmful to some viewers.</span>
              </em>
            </p>
            <p>&nbsp;</p>
            <p>
              <em>
                <span>
                  Without limiting the generality of the foregoing, this
                  progressive web app may include language or imagery that may
                  cause a traumatized person to relive a past experience,
                  especially related to self-harm, sexual assault, or suicide.
                </span>
              </em>
            </p>
            <p>&nbsp;</p>
            <p>
              <em>
                <span>Viewer discretion is advised.</span>
              </em>
            </p>
          </IonText>
        )}
      </IonContent>
      <IonButton onClick={acceptTerms}>Accept Terms and Conditions</IonButton>
    </IonModal>
  );
};

export default TermsAndConditions;

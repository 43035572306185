import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import Vimeo from '@u-wave/react-vimeo';
import { DataStore, SortDirection } from 'aws-amplify';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ContentCard from '../../components/ContentCard';
import CustomModal from '../../components/CustomModal';
import { AWARDS, HOME } from '../../constants';
import { useNextContent } from '../../hooks/useNextContent';
import { useOnEndContent } from '../../hooks/useOnEndContent';
import { useOnStartPlaylist } from '../../hooks/useOnStartPlaylist';
import type { Video } from '../../models';
import {
  Language,
  Playlist,
  Student,
  Content,
  WatchedPlaylist,
} from '../../models';
import { setStudentData, StudentContext } from '../../reducers/student';
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '../../utils/localStorage';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

type PlaylistPageProps = RouteComponentProps<
  any,
  any,
  {
    video?: Video;
    playlistID: string;
    contentID?: string;
  }
>;

const PlaylistPage: React.FC<PlaylistPageProps> = ({ location }) => {
  const history = useHistory();
  const [present, dismiss] = useIonToast();
  const { student, studentDispatch } = useContext(StudentContext);
  const [contents, setContents] = useState<Content[]>([]);
  const [playlist, setPlaylist] = useState<Playlist>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { video, contentID, playlistID: tempPlaylistID } = location.state || {};
  const [playlistID, setPlaylistID] = useState<string>(tempPlaylistID);
  const {
    id: studentID = '',
    language,
    completedContents = [],
    completedPlaylists = [],
  } = student || {};
  const { title, image, description, Award: award } = playlist || {};
  const currentContentID = contentID || contents[0]?.id;
  const currentVideo = video || contents[0]?.Video;
  const [updateCompletedContents] = useOnEndContent();
  const [checkStartedPlaylist] = useOnStartPlaylist();
  const { goToPage, getNextContent } = useNextContent(playlistID);

  useEffect(() => {
    if (playlistID) {
      setToLocalStorage('playlistID', playlistID);
    } else {
      setPlaylistID(getFromLocalStorage('playlistID'));
    }
  }, [playlistID]);

  useEffect(() => {
    const isPlaylistCompleted =
      contents.length &&
      contents.every(content => completedContents.includes(content.id));
    if (
      studentID &&
      playlistID &&
      isPlaylistCompleted &&
      !completedPlaylists.includes(playlistID)
    ) {
      (async () => {
        const original = await DataStore.query(Student, studentID);
        const originalPlaylist = await DataStore.query(WatchedPlaylist, c =>
          c.playlist_id('eq', playlistID).student_id('eq', studentID),
        );
        if (original) {
          studentDispatch(
            setStudentData(
              await DataStore.save(
                Student.copyOf(original, updated => {
                  updated.completedPlaylists.push(playlistID);
                }),
              ),
            ),
          );
        }
        if (originalPlaylist) {
          await DataStore.save(
            WatchedPlaylist.copyOf(originalPlaylist[0], updated => {
              updated.status = 'COMPLETED';
            }),
          );
        }
      })();
      setShowModal(true);
    }
  }, [
    contents,
    studentID,
    playlistID,
    studentDispatch,
    completedContents,
    completedPlaylists,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const data = await DataStore.query(Playlist, playlistID);
        setPlaylist(data);
      } catch (error: any) {
        console.log('error fetch playlist:', error);
        present(
          getToastParams(
            {
              message: error.message,
            },
            dismiss,
          ),
        );
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistID]);

  useEffect(() => {
    (async () => {
      try {
        const data = await DataStore.query(
          Content,
          c => c.playlistID('eq', playlistID),
          {
            sort: s => s.order(SortDirection.ASCENDING),
          },
        );
        setContents(data);
      } catch (error: any) {
        console.log('error fetch content:', error);
        present(
          getToastParams(
            {
              message: error.message,
            },
            dismiss,
          ),
        );
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistID]);
  const onModalDimiss = () => {
    setShowModal(false);
    history.push(HOME);
  };
  const myRef = useRef<any>();
  const renderVideo = () => {
    if (currentVideo) {
      const { englishUrl, cantoneseUrl, mandarinUrl, spanishUrl } =
        currentVideo;
      let url;
      if (language === Language.MANDARIN) {
        url = mandarinUrl;
      } else if (language === Language.CANTONESE) {
        url = cantoneseUrl;
      } else if (language === Language.SPANISH) {
        url = spanishUrl;
      } else {
        url = englishUrl;
      }
      return (
        <>
          <span ref={myRef}></span>
          <Vimeo
            responsive
            video={url || ''}
            onEnd={async () => {
              await updateCompletedContents(currentContentID);
              const content = getNextContent(currentContentID);
              if (content) {
                goToPage(content);
              }
            }}
            onPlay={async () => {
              if (!completedPlaylists.includes(playlistID)) {
                await checkStartedPlaylist(playlistID, studentID);
              }
            }}
          />
        </>
      );
    }
  };

  return (
    <IonPage id="playlist-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={HOME} />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {renderVideo()}
        <h4>{currentVideo?.title}</h4>
        <hr className="ion-no-margin" />
        <Tabs>
          <TabList>
            <Tab>Videos</Tab>
            <Tab>Overview</Tab>
          </TabList>
          <hr className="ion-no-margin" />
          <TabPanel>
            {contents.map(content => (
              <ContentCard
                key={content.id}
                content={content}
                goToPage={goToPage}
                scrollTo={() =>
                  myRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                  })
                }
                completed={completedContents.includes(content.id)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <ReactMarkdown>{description || ''}</ReactMarkdown>
                </IonCol>
              </IonRow>
            </IonGrid>
          </TabPanel>
        </Tabs>
        {showModal && (
          <CustomModal
            hasClose
            amplifyImage={image}
            title="Congratulations!"
            description={`You've completed "${title}". ${
              award ? 'Your award is ready!' : ''
            }`}
            cta={
              award && (
                <IonButton
                  onClick={e => {
                    e.preventDefault();
                    setShowModal(false);
                  }}
                >
                  Continue
                </IonButton>
              )
            }
            onDidDismiss={() => onModalDimiss()}
          />
        )}
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(PlaylistPage);

import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import Slider from 'react-slick';

import './styles.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import type { Explore } from '../../models';
import CustomCarousel from '../CustomCarousel';
import ExploreCard from '../ExploreCard';

import './styles.scss';

interface ExploresCarouselProps {
  title?: string;
  explores: Explore[];
}

const ExploresCarousel: React.FC<ExploresCarouselProps> = ({
  title,
  explores,
}) => {
  if (!explores.length) return null;
  const expLength = explores.length;
  const settings = {
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div id="explores-carousel">
      <IonGrid>
        {title && (
          <IonRow>
            <IonCol>
              <h4>{title.replace(/[^a-zA-Z ]/g, ' ')}</h4>
            </IonCol>
          </IonRow>
        )}
        <IonRow className="explores-cards">
          <IonCol>
            <CustomCarousel slidesLength={explores.length}>
              {explores.map((explore, index) => (
                <div key={index} className="explore-item">
                  <ExploreCard key={index} explore={explore} />
                </div>
              ))}
            </CustomCarousel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ExploresCarousel;

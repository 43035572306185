import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  useIonToast,
} from '@ionic/react';
import { Auth, Hub } from 'aws-amplify';
import React, { memo, useContext, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  AuthContext,
  setAuthStatus,
  setCurrentUserAttributes,
} from '../../reducers/auth';

import {
  REGISTER,
  RESET_PASSWORD,
  STUDENT,
  IS_AUTHENTICATED,
  CURRENT_USER_ATTRIBUTES,
} from '../../constants';
import { useAuthStore } from '../../contexts/auth';
import { setStudentData, StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';
import { University } from '../../models';
import { DataStore } from 'aws-amplify';
import { setToLocalStorage } from '../../utils/localStorage';

import './style.scss';
import { Student } from '../../models';

const LoginPage: React.FC = () => {
  const history = useHistory();
  const { student, studentDispatch } = useContext(StudentContext);
  const { currentUserAttributes, authDispatch } = useContext(AuthContext);

  const [present, dismiss] = useIonToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(currentUserAttributes?.email);
  const [password, setPassword] = useState<string>('');
  const [warningText, setWarningText] = useState(false);
  const isDisabled = !email || !password || isLoading;

  useEffect(() => {
    if (student !== undefined) {
      window.location.reload();
    }
    console.log(student);
  }, [student]);

  const signIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { attributes } = await Auth.signIn({
        username: email,
        password,
      });
      DataStore.start();
      await waitForDataStoreToLoad();

      const universityDomain = attributes.email.split('@')[1];
      const university = await DataStore.query(University, c =>
        c.domain('eq', universityDomain),
      );

      if (attributes && university[0]?.activate === true) {
        const student = await DataStore.query(Student, c =>
          c.email('eq', attributes?.email),
        );

        studentDispatch(setStudentData(student[0] || null));
        setToLocalStorage(IS_AUTHENTICATED, true);
        authDispatch(setAuthStatus(true));
        authDispatch(
          setCurrentUserAttributes({ ...attributes, ...university[0] }),
        );
        setWarningText(false);
      } else if (attributes && university[0]?.activate !== true) {
        await Auth.signOut();
        authDispatch(setAuthStatus(false));
        setToLocalStorage(STUDENT, null);
        setToLocalStorage(IS_AUTHENTICATED, null);
        setToLocalStorage(CURRENT_USER_ATTRIBUTES, null);
        setWarningText(true);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.name === 'UserNotConfirmedException') {
        setCurrentUserAttributes({ email });
        history.replace(REGISTER);
      }
      present(
        getToastParams(
          {
            message: error.message,
          },
          dismiss,
        ),
      );
    }
  };

  const waitForDataStoreToLoad = async () => {
    await new Promise<void>(resolve => {
      Hub.listen('datastore', async capsule => {
        const { event } = capsule.payload;
        if (event === 'ready') {
          resolve();
        }
      });
    });
  };

  return (
    <IonPage id="login-page">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText className="auth-pages-text">
                <h1>Login</h1>
                <p>Welcome back! Login using your email and password</p>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <form className="form form-login" onSubmit={signIn}>
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput
                    required
                    type="email"
                    value={email}
                    onIonChange={e => setEmail(e.detail.value || '')}
                  />
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    required
                    type="password"
                    value={password}
                    onIonChange={e => setPassword(e.detail.value || '')}
                  />
                </IonItem>
                <p className="ion-text-center">
                  Forgot your password?{' '}
                  <Link to={RESET_PASSWORD} className="link-secondary">
                    Click here to reset it
                  </Link>
                </p>
                {warningText && (
                  <p style={{ color: 'red', textAlign: 'center' }}>
                    Your organization’s account is not currently active. <br />
                    Contact your organization for assistance
                  </p>
                )}
                <IonButton
                  size="large"
                  type="submit"
                  expand="block"
                  className="custom-button-margin-top"
                  disabled={isDisabled}
                >
                  Login
                </IonButton>
                <IonText className="ion-text-center">
                  <p>
                    Don't have an account yet? <Link to={REGISTER}>SignUp</Link>
                  </p>
                </IonText>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {!warningText ? (
        <IonLoading isOpen={isLoading} message={'Please wait...'} />
      ) : null}
    </IonPage>
  );
};

export default memo(LoginPage);

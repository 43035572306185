import {
  IonContent,
  IonToolbar,
  IonPage,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonLoading,
  useIonToast,
} from '@ionic/react';
import { DataStore } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import ContactsList from '../../components/ContactsList';
import type { Location } from '../../models/';
import { Contact } from '../../models/';
import { StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

type ContactsPageProps = RouteComponentProps<any, any, { medical: boolean }>;

const ContactsPage: React.FC<ContactsPageProps> = ({ location }) => {
  const [present, dismiss] = useIonToast();
  const { student } = useContext(StudentContext);
  const { universityID } = student || {};
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { medical } = location.state || {};

  useEffect(() => {
    if (universityID) {
      (async () => {
        try {
          const data = await DataStore.query(Contact, c =>
            c.and(c => c.universityID('eq', universityID)),
          );
          const filterData = data.filter(d =>
            medical ? d.medical : !d.medical,
          );

          setContacts(filterData);
        } catch (error: any) {
          console.log('error fetch contacts', error);
          present(
            getToastParams(
              {
                message: error.message,
              },
              dismiss,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityID]);

  return (
    <IonPage id="contacts-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          {/* <IonTitle>{medical ? 'Medical help' : 'Talk to someone'}</IonTitle> */}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <ContactsList contacts={contacts} />
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default ContactsPage;

import { AmplifyS3Image } from '@aws-amplify/ui-react';
import { IonCard, IonCardTitle, IonIcon } from '@ionic/react';
import {
  documentTextOutline,
  imageOutline,
  playCircleOutline,
} from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { EXPLORE_DETAILS } from '../../constants';
import type { Explore } from '../../models';
import VimeoThumbnail from '../VimeoThumbnail';

import './styles.scss';

interface ExploreCardProps {
  explore: Explore;
}

const ExploreCard: React.FC<ExploreCardProps> = ({ explore }) => {
  const history = useHistory();
  const { Video: video, Graphic: graphic, Infographic: infographic } = explore;

  const goToPage = () => {
    history.push(EXPLORE_DETAILS, { explore });
  };

  const renderCard = () => {
    if (video) {
      const { title, englishUrl } = video as any;
      return (
        <IonCard>
          <IonIcon src={playCircleOutline} />
          <VimeoThumbnail videoURL={englishUrl} />
          <IonCardTitle>{title}</IonCardTitle>
        </IonCard>
      );
    }
    const { title, image } = graphic || infographic || {};
    return (
      <IonCard>
        {graphic ? (
          <IonIcon src={imageOutline} />
        ) : (
          <IonIcon src={documentTextOutline} />
        )}
        <AmplifyS3Image imgKey={image} />
        <IonCardTitle>{title}</IonCardTitle>
      </IonCard>
    );
  };

  return (
    <div id="explore-card" onClick={goToPage}>
      {renderCard()}
    </div>
  );
};

export default ExploreCard;

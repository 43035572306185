import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { DataStore, SortDirection } from 'aws-amplify';
import React, { memo, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import QuestionContainer from '../../components/QuestionContainer';
import { useNextContent } from '../../hooks/useNextContent';
import { useOnEndContent } from '../../hooks/useOnEndContent';
import type { Survey } from '../../models';
import { Question } from '../../models';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

type SurveyPageProps = RouteComponentProps<
  any,
  any,
  { survey: Survey; contentID: string; playlistID: string }
>;

const SurveyPage: React.FC<SurveyPageProps> = ({ location }) => {
  const [present, dismiss] = useIonToast();
  const [updateCompletedContents] = useOnEndContent();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { contentID, survey, playlistID } = location.state || {};
  const { id, title } = survey || {};

  const { goToPage, getNextContent } = useNextContent(playlistID);

  const handlerOnEndContent = async () => {
    await updateCompletedContents(contentID);
    const content = getNextContent(contentID);
    if (content) {
      goToPage(content);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (id) {
      let isMounted = true;
      (async () => {
        try {
          const data = await DataStore.query(
            Question,
            q => q.surveyID('eq', id),
            {
              sort: s => s.order(SortDirection.ASCENDING),
            },
          );
          if (isMounted) setQuestions(data);
        } catch (error: any) {
          console.log('error fetching questions:', error);
          present(
            getToastParams(
              {
                message: error.message,
              },
              dismiss,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      })();
      return () => {
        isMounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <IonPage id="survey-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {Boolean(questions.length) && (
          <QuestionContainer
            survey={survey}
            questions={questions}
            handlerOnEndContent={handlerOnEndContent}
          />
        )}
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(SurveyPage);

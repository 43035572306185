import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Auth, DataStore, Hub } from 'aws-amplify';
import React, { useContext, useEffect, useReducer } from 'react';

import AppRouter from './AppRouter';
import {
  CURRENT_USER_ATTRIBUTES,
  IS_AUTHENTICATED,
  STUDENT,
} from './constants';
import { useDatastoreStatus } from './hooks/useDatastoreStatus';
import { Student } from './models';
import {
  setStudentData,
  StudentContext,
  studentInitialState,
  StudentReducer,
} from './reducers/student';
import { getFromLocalStorage } from './utils/localStorage';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/global.scss';
import './theme/variables.css';
import {
  AuthContext,
  authInitialState,
  AuthReducer,
  setAuthStatus,
  setCurrentUserAttributes,
} from './reducers/auth';

//

const App = () => {
  const [{ isAuthenticated, currentUserAttributes }, authDispatch] = useReducer(
    AuthReducer,
    authInitialState,
  );
  const [{ student }, studentDispatch] = useReducer(
    StudentReducer,
    studentInitialState,
  );
  const [isDatastoreReady] = useDatastoreStatus(isAuthenticated);

  useEffect(() => {
    const isAuthenticatedLocal = getFromLocalStorage(IS_AUTHENTICATED);
    if (isAuthenticatedLocal) {
      const currentUserAttributesLocal = getFromLocalStorage(
        CURRENT_USER_ATTRIBUTES,
      );
      authDispatch(setAuthStatus(true));
      authDispatch(setCurrentUserAttributes(currentUserAttributesLocal));
    } else {
      (async () => {
        try {
          const { attributes } = await Auth.currentAuthenticatedUser();
          authDispatch(setAuthStatus(false));
          authDispatch(setCurrentUserAttributes(attributes));
        } catch (error) {}
      })();
    }
  }, []);

  useEffect(() => {
    const studentLocal = getFromLocalStorage(STUDENT);
    if (studentLocal) {
      studentDispatch(setStudentData(studentLocal));
    } else {
      if (isDatastoreReady && currentUserAttributes?.email_verified) {
        (async () => {
          try {
            const currentUser = await DataStore.query(Student, c =>
              c.email('eq', currentUserAttributes?.email),
            );
            studentDispatch(setStudentData(currentUser[0] || null));
          } catch (error) {
            console.log('error current user:', error);
          }
        })();
      }
    }
  }, [isDatastoreReady, currentUserAttributes]);

  return (
    <IonApp>
      <AuthContext.Provider
        value={{ isAuthenticated, currentUserAttributes, authDispatch }}
      >
        <StudentContext.Provider
          value={{
            student,
            studentDispatch,
          }}
        >
          <IonReactRouter>
            <AppRouter />
          </IonReactRouter>
        </StudentContext.Provider>
      </AuthContext.Provider>
    </IonApp>
  );
};

export default App;

import { Auth } from 'aws-amplify';
import { useContext } from 'react';

import {
  STUDENT,
  IS_AUTHENTICATED,
  CURRENT_USER_ATTRIBUTES,
} from '../constants';
import { AuthContext, setAuthStatus } from '../reducers/auth';
import { setToLocalStorage } from '../utils/localStorage';

export const useSignOut = (): [(e: React.MouseEvent) => Promise<void>] => {
  const { authDispatch } = useContext(AuthContext);

  const cleanLocalStorage = async () => {
    setToLocalStorage(STUDENT, null);
    setToLocalStorage(IS_AUTHENTICATED, null);
    setToLocalStorage(CURRENT_USER_ATTRIBUTES, null);
  };

  const signOut = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await Auth.signOut();
      authDispatch(setAuthStatus(false));
      cleanLocalStorage();
      window.location.reload();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return [signOut];
};

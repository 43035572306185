import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import {
  home,
  homeOutline,
  star,
  starOutline,
  compass,
  compassOutline,
  handLeft,
  handLeftOutline,
  grid,
  gridOutline,
} from 'ionicons/icons';
import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import {
  HOME,
  AWARDS,
  EXPLORE,
  HELP,
  MORE,
  PROFILE,
  SETTINGS,
  APP_TERMS,
  CHAT,
  MEDICAL,
  REPORT,
  POLICY,
  PLAYLIST,
  QUIZ,
  SURVEY,
  CONTACTS,
} from './constants';
import AppTermsPage from './pages/app-terms';
import AwardsPage from './pages/awards';
import ChatPage from './pages/chat';
import ExplorePage from './pages/explore';
import HelpPage from './pages/help';
import HomePage from './pages/home';
import MedicalPage from './pages/medical';
import MorePage from './pages/more';
import PolicyPage from './pages/policy';
import ProfilePage from './pages/profile';
import ReportPage from './pages/report';
import SettingsPage from './pages/settings';
import PlaylistPage from './pages/playlist';
import QuizPage from './pages/quiz';
import SurveyPage from './pages/survey';
import ContactsPage from './pages/contacts';

const AppTabs: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<string>(HOME);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact render={() => <HomePage />} path={HOME} />
        <Route exact render={() => <AwardsPage />} path={AWARDS} />
        <Route exact render={() => <ExplorePage />} path={EXPLORE} />
        <Route exact render={() => <HelpPage />} path={HELP} />
        <Route exact render={() => <MorePage />} path={MORE} />
        <Route exact render={() => <ProfilePage />} path={PROFILE} />
        <Route exact render={() => <SettingsPage />} path={SETTINGS} />
        <Route exact render={() => <AppTermsPage />} path={APP_TERMS} />
        <Route exact render={() => <ChatPage />} path={CHAT} />
        <Route exact render={() => <MedicalPage />} path={MEDICAL} />
        <Route exact render={() => <ReportPage />} path={REPORT} />
        <Route exact component={ContactsPage} path={CONTACTS} />
        <Route exact render={() => <PolicyPage />} path={POLICY} />
        <Route exact component={PlaylistPage} path={PLAYLIST} />
        <Route exact component={QuizPage} path={QUIZ} />
        <Route exact component={SurveyPage} path={SURVEY} />
      </IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        onIonTabsDidChange={tab => {
          setCurrentTab(tab.detail.tab);
        }}
      >
        <IonTabButton tab={HOME} href={HOME}>
          <IonIcon icon={currentTab === HOME ? home : homeOutline} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab={AWARDS} href={AWARDS}>
          <IonIcon icon={currentTab === AWARDS ? star : starOutline} />
          <IonLabel>Awards</IonLabel>
        </IonTabButton>
        <IonTabButton tab={EXPLORE} href={EXPLORE}>
          <IonIcon icon={currentTab === EXPLORE ? compass : compassOutline} />
          <IonLabel>Explore</IonLabel>
        </IonTabButton>
        <IonTabButton tab={HELP} href={HELP}>
          <IonIcon icon={currentTab === HELP ? handLeft : handLeftOutline} />
          <IonLabel>Help</IonLabel>
        </IonTabButton>
        <IonTabButton tab={MORE} href={MORE}>
          <IonIcon icon={currentTab === MORE ? grid : gridOutline} />
          <IonLabel>More</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default AppTabs;

import { AmplifyS3Image } from '@aws-amplify/ui-react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { DataStore, Storage } from 'aws-amplify';
import { downloadOutline } from 'ionicons/icons';
import React, { memo, useContext, useEffect, useState, useRef } from 'react';

import AwardCard from '../../components/AwardCard';
import CustomModal from '../../components/CustomModal';
import ProgressCard from '../../components/ProgressCard';
import { useDatastoreQuery } from '../../hooks/useDatastoreQuery';
import type { Playlist } from '../../models';
import { University } from '../../models';
import { StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';
import { lockClosedOutline } from 'ionicons/icons';
import './style.scss';

const AwardsPage: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [present, dismiss] = useIonToast();
  const { student } = useContext(StudentContext);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const {
    username,
    universityID,
    completedPlaylists = [],
  } = student || ({} as any);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCanvasReady, setIsCanvasReady] = useState<boolean>(false);
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const { queryPlaylists } = useDatastoreQuery(universityID);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [university, setUniversity] = useState<University>();
  const [fileUrl, setFileUrl] = useState<string>('');

  useEffect(() => {
    uniImage();
  }, [university]);

  const uniImage = async () => {
    if (university) {
      const result = await Storage.get(university.certificat as string);

      setFileUrl(result.toString());
    }
  };

  useEffect(() => {
    (async () => {
      if (universityID) {
        try {
          const university = await DataStore.query(University, universityID);
          setUniversity(university);
        } catch (error: any) {
          console.log('fetch university:', error);
          present(
            getToastParams(
              {
                message: error.message,
              },
              dismiss,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (universityID) {
      (async () => {
        try {
          const data = await queryPlaylists();
          if (isMounted) setPlaylists(data);
        } catch (error: any) {
          console.log('error fetch playlists:', error);
          present(
            getToastParams(
              {
                message: error.message,
              },
              dismiss,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      })();
      return () => {
        isMounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityID]);

  const download = (url: string) => {
    const canvas = canvasRef.current;
    const image = imgRef.current;
    const ctx = canvas?.getContext('2d');
    if (ctx) {
      ctx.canvas.width = 3565;
      ctx.canvas.height = 2547;
      ctx.drawImage(image as HTMLImageElement, 0, 0);
      ctx.lineWidth = 1;
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';
      if (username) {
        ctx.font = 'italic 200px "Times New Roman", Times, serif';
        ctx.fillText(username, 1750, 1350);
        ctx.font = '70px Arial, Helvetica, sans-serif';
        ctx.fillText(
          new Date(Date.now()).toLocaleString().split(',')[0],
          2800,
          2030,
        );
      }
      const a = document.createElement('a');
      a.href = canvasRef.current?.toDataURL() as any;
      a.download = 'certificate.png';
      a.click();
    }
  };

  const hasFinished =
    playlists.length &&
    playlists
      .filter(playlists => playlists.mandatory)
      .every(playlist => completedPlaylists.includes(playlist.id));

  return (
    <IonPage id="awards-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Awards</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <ProgressCard
          isAward
          playlists={playlists}
          completedPlaylists={completedPlaylists}
        />

        <IonGrid>
          <IonRow className="award-cards">
            {playlists.map(
              ({ id, Award: award }) =>
                award && (
                  <IonCol key={id} size="6">
                    <AwardCard
                      playlistID={id}
                      award={award}
                      completed={completedPlaylists.includes(id)}
                    />
                  </IonCol>
                ),
            )}
          </IonRow>
        </IonGrid>
        <IonGrid>
          {hasFinished ? (
            <img src={fileUrl} />
          ) : (
            <div className="container">
              <img src={fileUrl} style={{ opacity: '30%' }} />
              <div className="positioner">
                <div className="icon">
                  <IonIcon src={lockClosedOutline} size="large" />
                </div>
              </div>
            </div>
          )}
        </IonGrid>
        <IonRow>
          <IonCol>
            {hasFinished ? (
              <IonButton
                className="
              test"
                size="large"
                expand="block"
                fill="outline"
                onClick={() => download(fileUrl)}
                disabled={isLoadingImage}
              >
                <IonIcon src={downloadOutline} slot="start" />
                Download Certificate
              </IonButton>
            ) : (
              <IonButton
                className="
              test"
                size="large"
                expand="block"
                fill="outline"
                onClick={() => setShowModal(true)}
              >
                Download Certificate
              </IonButton>
            )}
          </IonCol>
        </IonRow>
        {showModal && (
          <CustomModal
            hasClose
            title="You haven’t earned your certificate"
            description="This award can be earned by completing all required playlists"
            onDidDismiss={() => setShowModal(false)}
          />
        )}
        <canvas ref={canvasRef} />
        {fileUrl && (
          <img
            id="certificate"
            ref={imgRef}
            crossOrigin="anonymous"
            src={fileUrl}
            onLoad={() => setIsLoadingImage(false)}
          />
        )}
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(AwardsPage);

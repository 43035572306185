import type { ToastOptions } from '@ionic/react';
import type { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';

export const getToastParams = (
  options: ToastOptions & HookOverlayOptions,
  dismiss: () => void,
): any => {
  return {
    buttons: [
      {
        text: 'Dismiss',
        handler: dismiss,
      },
    ],
    duration: 5000,
    ...options,
  };
};

import { DataStore } from 'aws-amplify';
import { useState, useContext, useEffect } from 'react';

import { Student } from '../models';
import { setStudentData, StudentContext } from '../reducers/student';

export const useOnEndContent = (): [(contentID: string) => Promise<void>] => {
  const { student, studentDispatch } = useContext(StudentContext);
  const { id: studentID = '', completedContents = [] } = student || {};
  const [newStudentData, setNewStudent] = useState<Student>();

  useEffect(() => {
    if (newStudentData) {
      studentDispatch(setStudentData(newStudentData));
    }
  }, [newStudentData, studentDispatch]);

  const updateCompletedContents = async (contentID: string) => {
    if (!completedContents.includes(contentID) && studentID) {
      try {
        const original = await DataStore.query(Student, studentID);
        if (original) {
          setNewStudent(
            await DataStore.save(
              Student.copyOf(original, updated => {
                updated.completedContents.push(contentID);
              }),
            ),
          );
        }
      } catch (error) {
        console.log('error updateCompletedContents:', error);
      }
    }
  };

  return [updateCompletedContents];
};

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonToast,
  IonText,
} from '@ionic/react';
import { DataStore } from 'aws-amplify';
import React, { memo, useContext, useState } from 'react';
import { useAuthStore } from '../../contexts/auth';

import {
  Gender,
  Language,
  Student,
  University,
  UniversityYear,
} from '../../models';
import { AuthContext } from '../../reducers/auth';
import { setStudentData, StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';
import './style.scss';

const RegisterPage: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const [gender, setGender] = useState<keyof typeof Gender>();
  const [username, setUsername] = useState<string>('');
  const [focus, setFocus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [universityYear, setUniversityYear] =
    useState<keyof typeof UniversityYear>();
  const [videoLanguage, setVideoLanguage] = useState<keyof typeof Language>();
  const { currentUserAttributes } = useContext(AuthContext);
  const { studentDispatch } = useContext(StudentContext);

  const isDisabled =
    !videoLanguage || !username || !gender || !universityYear || isLoading;

  const createStudent = async (e: React.FormEvent) => {
    e.preventDefault();

    if (gender && universityYear && videoLanguage) {
      setIsLoading(true);
      try {
        const university = await DataStore.query(University, c =>
          c.domain('eq', currentUserAttributes.email.split('@')[1]),
        );
        const student = await DataStore.save(
          new Student({
            gender,
            username,
            universityYear,
            completedContents: [],
            completedPlaylists: [],
            language: videoLanguage,
            universityID: university[0].id,
            email: currentUserAttributes.email,
            acceptedTerms: false,
            createdDate: new Date().toISOString(),
          }),
        );
        studentDispatch(setStudentData(student));
      } catch (error: any) {
        console.log('error create student:', error);
        setIsLoading(false);
        present(
          getToastParams(
            {
              message: error.message,
            },
            dismiss,
          ),
        );
      }
    } else {
      present(
        getToastParams(
          {
            message: 'Fill in all the required fields.',
          },
          dismiss,
        ),
      );
    }
  };

  return (
    <IonPage id="register-page">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1>Let’s know you better</h1>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <form onSubmit={createStudent}>
                <IonItem>
                  <IonLabel position="floating">Create a username</IonLabel>
                  <IonInput
                    onIonFocus={() => setFocus(true)}
                    onIonBlur={() => setFocus(false)}
                    required
                    type="text"
                    value={username}
                    onIonChange={e => setUsername(e.detail.value || '')}
                  />
                </IonItem>
                {focus && (
                  <IonText>
                    <p>This username will appear on your certificate</p>
                  </IonText>
                )}
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">
                    What do you identify as?
                  </IonLabel>
                  <IonSelect
                    mode="ios"
                    value={gender}
                    onIonChange={e => setGender(e.detail.value)}
                  >
                    {Object.entries(Gender).map(([key, value]) => (
                      <IonSelectOption key={key} value={key}>
                        {value}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">
                    Select your school year
                  </IonLabel>
                  <IonSelect
                    mode="ios"
                    value={universityYear}
                    onIonChange={e => setUniversityYear(e.detail.value)}
                  >
                    {Object.entries(UniversityYear).map(([key, value]) => (
                      <IonSelectOption key={key} value={key}>
                        {value.replace(/[^a-zA-Z ]/g, ' ')}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">Select video language</IonLabel>
                  <IonSelect
                    mode="ios"
                    value={videoLanguage}
                    onIonChange={e => setVideoLanguage(e.detail.value)}
                  >
                    {Object.entries(Language).map(([key, value]) => (
                      <IonSelectOption key={key} value={key}>
                        {value}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonButton
                  size="large"
                  type="submit"
                  expand="block"
                  className="custom-button-margin-top"
                  disabled={isDisabled}
                >
                  Submit
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(RegisterPage);

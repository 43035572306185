import { IonImg } from '@ionic/react';
import React from 'react';

import BoyImage from '../../assets/images/boy.svg';
import GirlImage from '../../assets/images/girl.svg';
import NoneImage from '../../assets/images/none.png';
import { Gender } from '../../models';

import './styles.scss';

interface CustomAvatarProps {
  gender?: keyof typeof Gender;
}

const CustomAvatar: React.FC<CustomAvatarProps> = ({ gender }) => {
  const getImage = () => {
    if (gender === Gender.MALE) {
      return BoyImage;
    }
    if (gender === Gender.FEMALE) {
      return GirlImage;
    }
    return NoneImage;
  };

  return (
    <div id="custom-avatar">
      <div className="custom-avatar-image">
        <IonImg src={getImage()} />
      </div>
    </div>
  );
};

export default CustomAvatar;

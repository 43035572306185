import type { Dispatch } from 'react';
import { createContext } from 'react';

import { STUDENT } from '../constants';
import type { Student } from '../models';
import { setToLocalStorage } from '../utils/localStorage';

export const studentInitialState = {
  student: undefined,
  studentDispatch: (): null => null,
};

const SET_STUDENT_DATA = 'SET_STUDENT_DATA';

type State = {
  student?: Student;
  studentDispatch: Dispatch<Action>;
};

type Action = ReturnType<typeof setStudentData>;

export const setStudentData = (studentData: Student) =>
  <const>{
    type: SET_STUDENT_DATA,
    studentData,
  };

export const StudentReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_STUDENT_DATA:
      setToLocalStorage(STUDENT, action.studentData);
      return {
        ...state,
        student: action.studentData,
      };
    default:
      return state;
  }
};

export const StudentContext = createContext<State>(studentInitialState);

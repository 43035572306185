import { AmplifyS3Image } from '@aws-amplify/ui-react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLoading,
  IonPage,
  IonRow,
  useIonToast,
} from '@ionic/react';
import { DataStore } from 'aws-amplify';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import RootImage from '../../assets/images/gainfull_blue_enough_full.png';
import { HOME } from '../../constants';
import { University } from '../../models';
import { StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

const WelcomePage: React.FC = () => {
  const history = useHistory();
  const [present, dismiss] = useIonToast();
  const { student } = useContext(StudentContext);
  const { universityID } = student || {};
  const [university, setUniversity] = useState<University>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { logo } = university || {};

  useEffect(() => {
    (async () => {
      if (universityID) {
        try {
          const university = await DataStore.query(University, universityID);
          setUniversity(university);
        } catch (error: any) {
          console.log('fetch university:', error);
          present(
            getToastParams(
              {
                message: error.message,
              },
              dismiss,
            ),
          );
        } finally {
          setIsLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student, universityID]);

  const goToPage = (e: React.MouseEvent, page: string) => {
    e.preventDefault();
    history.push(page);
  };

  return (
    <IonPage id="welcome-page">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              <h1>Welcome!</h1>
              {logo && <AmplifyS3Image imgKey={logo} />}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center ion-margin">
              <IonImg className="blueseat-gif" src={RootImage} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <p>Start your training - tap the first playlist to begin!</p>
              <IonButton size="small" onClick={e => goToPage(e, HOME)}>
                Let's go!
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default memo(WelcomePage);

import { IonImg } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import './styles.scss';

interface VimeoThumbnailProps {
  videoURL?: string;
}

interface VimeoOEmbedAPIResponse {
  thumbnail_url: string;
}

const VimeoThumbnail: React.FC<VimeoThumbnailProps> = ({ videoURL }) => {
  const [videoThumbnail, setVideoThumbnail] = useState<string>();

  useEffect(() => {
    if (videoURL) {
      (async () => {
        const response = await fetch(
          `https://vimeo.com/api/oembed.json?url=${videoURL}`,
        );
        const data =
          (await response.json()) as unknown as VimeoOEmbedAPIResponse;
        setVideoThumbnail(data.thumbnail_url);
      })();
    }
  }, [videoURL]);

  return (
    <div id="vimeo-thumbnail" className="vimeo-thumbnail">
      {videoThumbnail && <IonImg src={videoThumbnail} />}
    </div>
  );
};

export default VimeoThumbnail;

import { DataStore } from 'aws-amplify';

import {
  Explore,
  Playlist,
  UniversityExplore,
  UniversityPlaylist,
} from '../models';

type DataStoreQueryReturnType = {
  queryExplores: () => Promise<Explore[]>;
  queryPlaylists: () => Promise<Playlist[]>;
};

export const useDatastoreQuery = (
  universityID?: string,
): DataStoreQueryReturnType => {
  const queryPlaylists = async () => {
    const playlistsIDs = (await DataStore.query(UniversityPlaylist))
      .filter(up => up.university.id === universityID)
      .map(up => up.playlist.id);

    const data = (await DataStore.query(Playlist)).filter(p =>
      playlistsIDs.includes(p.id),
    );

    return data;
  };

  const queryExplores = async () => {
    const exploresIDs = (await DataStore.query(UniversityExplore))
      .filter(ue => ue.university.id === universityID)
      .map(ue => ue.explore.id);

    const data = (await DataStore.query(Explore)).filter(e =>
      exploresIDs.includes(e.id),
    );

    return data;
  };

  return { queryExplores, queryPlaylists };
};

import { AmplifyS3Image } from '@aws-amplify/ui-react';
import { IonIcon, IonImg, IonModal } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React from 'react';

import './styles.scss';

interface CustomModalProps {
  title: string;
  image?: string;
  hasClose?: boolean;
  description: string;
  amplifyImage?: string;
  cta?: React.ReactNode;
  onDidDismiss: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({
  image,
  title,
  hasClose,
  description,
  amplifyImage,
  onDidDismiss,
  cta,
}) => {
  return (
    <IonModal
      isOpen
      cssClass="custom-modal ion-text-center"
      onDidDismiss={onDidDismiss}
    >
      {hasClose && (
        <IonIcon
          size="large"
          className="icon-close"
          src={closeOutline}
          onClick={onDidDismiss}
        />
      )}
      {image && <IonImg src={image} className="ion-padding" />}
      {amplifyImage && (
        <AmplifyS3Image imgKey={amplifyImage} className="ion-padding" />
      )}
      <h3>{title}</h3>
      <p>{description}</p>
      {cta}
    </IonModal>
  );
};

export default CustomModal;

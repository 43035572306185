import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { memo, useContext, useEffect } from 'react';

import CallImage from '../../assets/images/Call.png';
import LegalImage from '../../assets/images/Legal.png';
import MedicalImage from '../../assets/images/Medical.png';
import ReportImage from '../../assets/images/Report.png';
import CustomCard from '../../components/CustomCard';
import { CHAT, MEDICAL, REPORT, POLICY, CONTACTS } from '../../constants';
import { AuthContext } from '../../reducers/auth';
import { DataStore } from 'aws-amplify';

import './style.scss';
import { University } from '../../models';

const Cards = [
  {
    title: 'Contact Support',
    subtitle: 'Find helpful resources',
    image: CallImage,
    page: CONTACTS,
  },
  {
    title: 'Medical Treatment',
    subtitle: 'Find medical care',
    image: MedicalImage,
    page: MEDICAL,
  },
  {
    title: 'Send an Email',
    subtitle: 'Report an issue',
    image: ReportImage,
    page: REPORT,
    isEmail: true,
  },
  {
    title: 'Policy',
    subtitle: 'Policies and laws',
    image: LegalImage,
    page: POLICY,
  },
];

const HelpPage: React.FC = () => {
  const { currentUserAttributes } = useContext(AuthContext);

  return (
    <IonPage id="help-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Help</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          {Cards.map(({ title, subtitle, image, page, isEmail }) => (
            <IonRow key={title}>
              <IonCol>
                <CustomCard
                  title={title}
                  subtitle={subtitle}
                  image={image}
                  page={page}
                />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default memo(HelpPage);

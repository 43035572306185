import {
  IonContent,
  IonGrid,
  IonToolbar,
  IonPage,
  IonRow,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonCol,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonLoading,
  useIonToast,
} from '@ionic/react';
import { Auth, DataStore } from 'aws-amplify';
import React, { useContext, useState } from 'react';

import CustomAvatar from '../../components/CustomAvatar';
import { Student, Gender, UniversityYear } from '../../models';
import { setStudentData, StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

const ProfilePage: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const { student, studentDispatch } = useContext(StudentContext);
  const {
    id: studentID = '',
    email,
    gender,
    username,
    universityYear,
  } = student || {};
  const [newGender, setNewGender] =
    useState<keyof typeof Gender | undefined>(gender);
  const [newUniversityYear, setNewUniversityYear] =
    useState<keyof typeof UniversityYear | undefined>(universityYear);
  const [newUsername, setNewUsername] = useState<string | undefined>(username);
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDisabled =
    gender === newGender &&
    username === newUsername &&
    universityYear === newUniversityYear &&
    !(oldPassword && newPassword);

  const updateStudent = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const original = await DataStore.query(Student, studentID);
      if (original) {
        const newStudent = await DataStore.save(
          Student.copyOf(original, updated => {
            if (newUsername) {
              updated.username = newUsername;
            }
            if (gender) {
              updated.gender = newGender as Gender;
            }
            if (universityYear) {
              updated.universityYear = newUniversityYear as UniversityYear;
            }
          }),
        );
        const result = await changePassword();
        if (result) {
          studentDispatch(setStudentData(newStudent));
          present(
            getToastParams(
              {
                color: 'primary',
                message: 'Changes made successfully',
              },
              dismiss,
            ),
          );
        }
      }
    } catch (error: any) {
      console.log('error updateStudent:', error);
      present(
        getToastParams(
          {
            message: error.message,
          },
          dismiss,
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const changePassword = async () => {
    if (oldPassword && newPassword) {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
        return true;
      } catch (error: any) {
        present(
          getToastParams(
            {
              message: error.message,
            },
            dismiss,
          ),
        );
        return false;
      }
    }
    return true;
  };

  return (
    <IonPage id="profile-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <CustomAvatar gender={gender} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <form onSubmit={updateStudent}>
                <IonItem>
                  <IonLabel position="floating">Username</IonLabel>
                  <IonInput
                    type="text"
                    value={newUsername}
                    onIonChange={e => setNewUsername(e.detail.value || '')}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput disabled type="email" value={email} />
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">
                    What do you identify as?
                  </IonLabel>
                  <IonSelect
                    mode="ios"
                    value={newGender}
                    onIonChange={e => setNewGender(e.detail.value)}
                  >
                    {Object.entries(Gender).map(([key, value]) => (
                      <IonSelectOption key={key} value={key}>
                        {value.replace(/[^a-zA-Z ]/g, ' ')}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">
                    Select your school year
                  </IonLabel>
                  <IonSelect
                    mode="ios"
                    value={newUniversityYear}
                    onIonChange={e => setNewUniversityYear(e.detail.value)}
                  >
                    {Object.entries(UniversityYear).map(([key, value]) => (
                      <IonSelectOption key={key} value={key}>
                        {value.replace(/[^a-zA-Z ]/g, ' ')}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">Old Password</IonLabel>
                  <IonInput
                    type="password"
                    value={oldPassword}
                    onIonChange={e => setOldPassword(e.detail.value || '')}
                  />
                </IonItem>
                <IonItem className="ion-margin-top">
                  <IonLabel position="floating">New Password</IonLabel>
                  <IonInput
                    type="password"
                    value={newPassword}
                    onIonChange={e => setNewPassword(e.detail.value || '')}
                  />
                </IonItem>
                <IonButton
                  size="large"
                  type="submit"
                  expand="block"
                  disabled={isDisabled}
                  className="custom-button-margin-top"
                >
                  Save my information
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default ProfilePage;

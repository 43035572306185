import { DataStore } from 'aws-amplify';

import { WatchedPlaylist } from '../models';

export const useOnStartPlaylist = (): [
  (playlistID: string, studentID: string) => Promise<void>,
] => {
  const checkStartedPlaylist = async (
    playlistID: string,
    studentID: string,
  ) => {
    try {
      const original = await DataStore.query(WatchedPlaylist, c =>
        c.playlist_id('eq', playlistID),
      );
      if (!original.length) {
        await DataStore.save(
          new WatchedPlaylist({
            playlist_id: playlistID,
            student_id: studentID,
            status: 'STARTED',
          }),
        );
      }
    } catch (error) {
      console.log('error checkStartedPlaylist:', error);
    }
  };
  return [checkStartedPlaylist];
};

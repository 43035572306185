export const schema = {
  models: {
    WatchedPlaylist: {
      name: 'WatchedPlaylist',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        student_id: {
          name: 'student_id',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        playlist_id: {
          name: 'playlist_id',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        status: {
          name: 'status',
          isArray: false,
          type: {
            enum: 'WatchedStatus',
          },
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'WatchedPlaylists',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Answers: {
      name: 'Answers',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        student_id: {
          name: 'student_id',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        question_id: {
          name: 'question_id',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        answer: {
          name: 'answer',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Answers',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Report: {
      name: 'Report',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        category: {
          name: 'category',
          isArray: false,
          type: {
            enum: 'Category',
          },
          isRequired: true,
          attributes: [],
        },
        location: {
          name: 'location',
          isArray: false,
          type: {
            enum: 'Location',
          },
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        studentID: {
          name: 'studentID',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Reports',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byStudent',
            fields: ['studentID'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Contact: {
      name: 'Contact',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        image: {
          name: 'image',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        phone: {
          name: 'phone',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        email: {
          name: 'email',
          isArray: false,
          type: 'AWSEmail',
          isRequired: true,
          attributes: [],
        },
        locations: {
          name: 'locations',
          isArray: true,
          type: {
            enum: 'Location',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        conversation: {
          name: 'conversation',
          isArray: true,
          type: {
            enum: 'Conversation',
          },
          isRequired: true,
          attributes: [],
          isArrayNullable: true,
        },
        medical: {
          name: 'medical',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        universityID: {
          name: 'universityID',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Contacts',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byUniversity',
            fields: ['universityID'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Explore: {
      name: 'Explore',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        Infographic: {
          name: 'Infographic',
          isArray: false,
          type: {
            model: 'Infographic',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'exploreInfographicId',
          },
        },
        Graphic: {
          name: 'Graphic',
          isArray: false,
          type: {
            model: 'Graphic',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'exploreGraphicId',
          },
        },
        Video: {
          name: 'Video',
          isArray: false,
          type: {
            model: 'Video',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'exploreVideoId',
          },
        },
        category: {
          name: 'category',
          isArray: false,
          type: {
            enum: 'Category',
          },
          isRequired: true,
          attributes: [],
        },
        universitys: {
          name: 'universitys',
          isArray: true,
          type: {
            model: 'UniversityExplore',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'explore',
          },
        },
      },
      syncable: true,
      pluralName: 'Explores',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Infographic: {
      name: 'Infographic',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        image: {
          name: 'image',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Infographics',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Graphic: {
      name: 'Graphic',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        image: {
          name: 'image',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Graphics',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Video: {
      name: 'Video',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        categories: {
          name: 'categories',
          isArray: true,
          type: {
            enum: 'Category',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        englishUrl: {
          name: 'englishUrl',
          isArray: false,
          type: 'AWSURL',
          isRequired: false,
          attributes: [],
        },
        cantoneseUrl: {
          name: 'cantoneseUrl',
          isArray: false,
          type: 'AWSURL',
          isRequired: false,
          attributes: [],
        },
        mandarinUrl: {
          name: 'mandarinUrl',
          isArray: false,
          type: 'AWSURL',
          isRequired: false,
          attributes: [],
        },
        spanishUrl: {
          name: 'spanishUrl',
          isArray: false,
          type: 'AWSURL',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Videos',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    UniversityExplore: {
      name: 'UniversityExplore',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        university: {
          name: 'university',
          isArray: false,
          type: {
            model: 'University',
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'universityID',
          },
        },
        explore: {
          name: 'explore',
          isArray: false,
          type: {
            model: 'Explore',
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'exploreID',
          },
        },
      },
      syncable: true,
      pluralName: 'UniversityExplores',
      attributes: [
        {
          type: 'model',
          properties: {
            queries: null,
          },
        },
        {
          type: 'key',
          properties: {
            name: 'byUniversity',
            fields: ['universityID', 'exploreID'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'byExplore',
            fields: ['exploreID', 'universityID'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    University: {
      name: 'University',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        activate: {
          name: 'activate',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        logo: {
          name: 'logo',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        domain: {
          name: 'domain',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        contact: {
          name: 'contact',
          isArray: false,
          type: 'AWSEmail',
          isRequired: true,
          attributes: [],
        },
        policy: {
          name: 'policy',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        legal: {
          name: 'legal',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        termslabel: {
          name: 'termslabel',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        personalInfoStatement: {
          name: 'personalInfoStatement',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        disclaimerText: {
          name: 'disclaimerText',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        copyrightText: {
          name: 'copyrightText',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        passCriteria: {
          name: 'passCriteria',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: [],
        },
        Students: {
          name: 'Students',
          isArray: true,
          type: {
            model: 'Student',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'universityID',
          },
        },
        Contacts: {
          name: 'Contacts',
          isArray: true,
          type: {
            model: 'Contact',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'universityID',
          },
        },
        UniversityPlaylists: {
          name: 'UniversityPlaylists',
          isArray: true,
          type: {
            model: 'UniversityPlaylist',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'university',
          },
        },
        UniversityExplores: {
          name: 'UniversityExplores',
          isArray: true,
          type: {
            model: 'UniversityExplore',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'university',
          },
        },
        certificat: {
          name: 'certificat',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Universities',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Student: {
      name: 'Student',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        email: {
          name: 'email',
          isArray: false,
          type: 'AWSEmail',
          isRequired: true,
          attributes: [],
        },
        username: {
          name: 'username',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        gender: {
          name: 'gender',
          isArray: false,
          type: {
            enum: 'Gender',
          },
          isRequired: true,
          attributes: [],
        },
        universityYear: {
          name: 'universityYear',
          isArray: false,
          type: {
            enum: 'UniversityYear',
          },
          isRequired: true,
          attributes: [],
        },
        language: {
          name: 'language',
          isArray: false,
          type: {
            enum: 'Language',
          },
          isRequired: true,
          attributes: [],
        },
        createdDate: {
          name: 'createdDate',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
        },
        completedContents: {
          name: 'completedContents',
          isArray: true,
          type: 'String',
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
        completedPlaylists: {
          name: 'completedPlaylists',
          isArray: true,
          type: 'String',
          isRequired: false,
          attributes: [],
          isArrayNullable: false,
        },
        notifications: {
          name: 'notifications',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        reminders: {
          name: 'reminders',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
        universityID: {
          name: 'universityID',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        Reports: {
          name: 'Reports',
          isArray: true,
          type: {
            model: 'Report',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'studentID',
          },
        },
        acceptedTerms: {
          name: 'acceptedTerms',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Students',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byUniversity',
            fields: ['universityID'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    UniversityPlaylist: {
      name: 'UniversityPlaylist',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        university: {
          name: 'university',
          isArray: false,
          type: {
            model: 'University',
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'universityID',
          },
        },
        playlist: {
          name: 'playlist',
          isArray: false,
          type: {
            model: 'Playlist',
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'playlistID',
          },
        },
      },
      syncable: true,
      pluralName: 'UniversityPlaylists',
      attributes: [
        {
          type: 'model',
          properties: {
            queries: null,
          },
        },
        {
          type: 'key',
          properties: {
            name: 'byUniversity',
            fields: ['universityID', 'playlistID'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'byPlaylist',
            fields: ['playlistID', 'universityID'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Playlist: {
      name: 'Playlist',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        image: {
          name: 'image',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        duration: {
          name: 'duration',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        videoCount: {
          name: 'videoCount',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        mandatory: {
          name: 'mandatory',
          isArray: false,
          type: 'Boolean',
          isRequired: true,
          attributes: [],
        },
        categories: {
          name: 'categories',
          isArray: true,
          type: {
            enum: 'Category',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        Contents: {
          name: 'Contents',
          isArray: true,
          type: {
            model: 'Content',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'playlistID',
          },
        },
        Award: {
          name: 'Award',
          isArray: false,
          type: {
            model: 'Award',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'playlistAwardId',
          },
        },
        universitys: {
          name: 'universitys',
          isArray: true,
          type: {
            model: 'UniversityPlaylist',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'playlist',
          },
        },
      },
      syncable: true,
      pluralName: 'Playlists',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Content: {
      name: 'Content',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        order: {
          name: 'order',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: [],
        },
        playlistID: {
          name: 'playlistID',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        Survey: {
          name: 'Survey',
          isArray: false,
          type: {
            model: 'Survey',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'contentSurveyId',
          },
        },
        Video: {
          name: 'Video',
          isArray: false,
          type: {
            model: 'Video',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'contentVideoId',
          },
        },
        Quiz: {
          name: 'Quiz',
          isArray: false,
          type: {
            model: 'Quiz',
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'contentQuizId',
          },
        },
      },
      syncable: true,
      pluralName: 'Contents',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byPlaylist',
            fields: ['playlistID'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Survey: {
      name: 'Survey',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        image: {
          name: 'image',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        message: {
          name: 'message',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        categories: {
          name: 'categories',
          isArray: true,
          type: {
            enum: 'Category',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        Questions: {
          name: 'Questions',
          isArray: true,
          type: {
            model: 'Question',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'surveyID',
          },
        },
      },
      syncable: true,
      pluralName: 'Surveys',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Question: {
      name: 'Question',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        image: {
          name: 'image',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        options: {
          name: 'options',
          isArray: true,
          type: 'String',
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        points: {
          name: 'points',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: [],
        },
        correctAnswer: {
          name: 'correctAnswer',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: [],
        },
        messageCorrect: {
          name: 'messageCorrect',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        messageWrong: {
          name: 'messageWrong',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: [],
        },
        order: {
          name: 'order',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: [],
        },
        quizID: {
          name: 'quizID',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
        surveyID: {
          name: 'surveyID',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Questions',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'key',
          properties: {
            name: 'byQuiz',
            fields: ['quizID'],
          },
        },
        {
          type: 'key',
          properties: {
            name: 'bySurvey',
            fields: ['surveyID'],
          },
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Quiz: {
      name: 'Quiz',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        categories: {
          name: 'categories',
          isArray: true,
          type: {
            enum: 'Category',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        Questions: {
          name: 'Questions',
          isArray: true,
          type: {
            model: 'Question',
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'quizID',
          },
        },
      },
      syncable: true,
      pluralName: 'Quizzes',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
    Award: {
      name: 'Award',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: [],
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        image: {
          name: 'image',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: 'Awards',
      attributes: [
        {
          type: 'model',
          properties: {},
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read'],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    WatchedStatus: {
      name: 'WatchedStatus',
      values: ['STARTED', 'COMPLETED'],
    },
    Category: {
      name: 'Category',
      values: [
        'SEXUAL_HARASSMENT_PREVENTION',
        'BYSTANDER_TRAINING',
        'SEXUAL_ASSAULT_PREVENTION',
        'DATING_VIOLENCE_PREVENTION',
        'HEALTHY_RELATIONSHIPS',
        'ALCOHOL_AND_DRUG_AWARENESS',
        'MENTAL_HEALTH',
        'ANXIETY',
        'DEPRESSION',
        'BODY_IMAGE',
        'TRAUMA',
        'INTIMATE_PARTNER_VIOLENCE',
        'CONTROLLING_RELATIONSHIPS',
        'BOUNDARIES',
        'TITLE_IX',
        'SEXUAL_HEALTH',
        'SCENARIOS',
        'IS_IT_LOVE',
        'IS_MY_RELATIONSHIP_HEALTHY',
        'DO_YOU_PRACTICE_CONSENT',
        'SURVIVOR_TIPS',
        'COPING_WITH_ANXIETY',
        'IS_MY_ALCOHOL_USE_HEALTHY',
        'HOW_TO_BE_INCLUSIVE',
        'ADDRESSING_RACISM',
        'HOW_TO_BE_ANTI_RACIST',
        'MANAGING_STRESS',
        'MANAGING_TRAUMA',
        'GETTING_SUPPORT',
        'ASSAULT',
      ],
    },
    Location: {
      name: 'Location',
      values: ['CAMPUS', 'COMMUNITY'],
    },
    Conversation: {
      name: 'Conversation',
      values: ['CONFIDENTIAL', 'NONCONFIDENTIAL'],
    },
    Gender: {
      name: 'Gender',
      values: ['FEMALE', 'MALE', 'NONBINARY', 'CHOOSE_NOT_TO_ANSWER'],
    },
    UniversityYear: {
      name: 'UniversityYear',
      values: [
        'FIRST_YEAR_STUDENT',
        'SECOND_YEAR_STUDENT',
        'THIRD_YEAR_STUDENT',
        'FOURTH_YEAR_STUDENT',
        'GRADUATE_STUDENT',
        'STAFF',
        'OTHER',
      ],
    },
    Language: {
      name: 'Language',
      values: ['ENGLISH', 'CANTONESE', 'MANDARIN', 'SPANISH'],
    },
  },
  nonModels: {},
  codegenVersion: '3.3.5',
  version: '9e12891dc749df22913aa64c2bd7a553',
};

import { IonRouterOutlet } from '@ionic/react';
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AppTabs from './AppTabs';
import {
  REGISTER,
  LOGIN,
  ROOT,
  WELCOME,
  TABS,
  HOME,
  CONTACTS,
  EXPLORE_DETAILS,
  RESET_PASSWORD,
  CONFIRM,
  SET_PASSWORD,
} from './constants';
import { AuthContext } from './reducers/auth';

import ConfirmationPage from './pages/confirmation';
import ContactsPage from './pages/contacts';
import ExploreDetailsPage from './pages/explore-details';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import ResetPasswordPage from './pages/reset-password';
import SetPasswordPage from './pages/set-password';
import RootPage from './pages/root';
import SignUpPage from './pages/sign-up';
import WelcomePage from './pages/welcome';
import { StudentContext } from './reducers/student';

const AppRouter: React.FC = () => {
  const { student } = useContext(StudentContext);
  const { isAuthenticated, currentUserAttributes } = useContext(AuthContext);

  if (!isAuthenticated || student === undefined) {
    return (
      <IonRouterOutlet>
        <Route exact component={RootPage} path={ROOT} />
        <Route exact component={LoginPage} path={LOGIN} />
        <Route exact component={ResetPasswordPage} path={RESET_PASSWORD} />
        <Route exact component={SetPasswordPage} path={SET_PASSWORD} />
        <Route exact component={ConfirmationPage} path={CONFIRM} />
        <Route exact component={SignUpPage} path={REGISTER} />
        <Redirect to={ROOT} />
      </IonRouterOutlet>
    );
  }

  if (student === null) {
    return (
      <IonRouterOutlet>
        <Route exact component={RegisterPage} path={REGISTER} />
        <Redirect from={LOGIN} to={REGISTER} />
        <Redirect to={REGISTER} />
      </IonRouterOutlet>
    );
  }

  return (
    <IonRouterOutlet>
      <Route exact component={WelcomePage} path={WELCOME} />
      <Route exact component={ContactsPage} path={CONTACTS} />
      <Route exact component={ExploreDetailsPage} path={EXPLORE_DETAILS} />
      <Route component={AppTabs} path={TABS} />
      <Redirect from={LOGIN} to={HOME} />
      <Redirect from={REGISTER} to={WELCOME} />
      <Redirect to={HOME} />
    </IonRouterOutlet>
  );
};

export default AppRouter;

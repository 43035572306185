import {
  IonGrid,
  IonToolbar,
  IonPage,
  IonContent,
  IonRow,
  IonTitle,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonSelectOption,
  IonSelect,
  IonItem,
  IonLabel,
  IonButton,
  IonCol,
  IonLoading,
  useIonToast,
} from '@ionic/react';
import { DataStore } from 'aws-amplify';
import React, { useContext, useState } from 'react';

import { Language, Student } from '../../models';
import { setStudentData, StudentContext } from '../../reducers/student';
import { getToastParams } from '../../utils/toastParams';

import './style.scss';

const SettingsPage: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const { student, studentDispatch } = useContext(StudentContext);
  const { id: studentID = '', language } = student || {};
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newLanguage, setNewLanguage] =
    useState<keyof typeof Language | undefined>(language);

  const isDisabled = language === newLanguage;

  const updateLanguage = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const original = await DataStore.query(Student, studentID);
      if (original) {
        const newStudent = await DataStore.save(
          Student.copyOf(original, updated => {
            if (newLanguage) updated.language = newLanguage;
          }),
        );
        studentDispatch(setStudentData(newStudent));
        present(
          getToastParams(
            {
              color: 'primary',
              message: 'Language updated successfully',
            },
            dismiss,
          ),
        );
      }
    } catch (error: any) {
      console.log('error updateLanguage:', error);
      present(
        getToastParams(
          {
            message: error.message,
          },
          dismiss,
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IonPage id="settings-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <form onSubmit={updateLanguage}>
                <IonItem>
                  <IonLabel position="floating">Change Video Language</IonLabel>
                  <IonSelect
                    value={newLanguage}
                    onIonChange={e => setNewLanguage(e.detail.value)}
                  >
                    {Object.entries(Language).map(([key, value]) => (
                      <IonSelectOption key={key} value={key}>
                        {value}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
                <IonButton
                  size="large"
                  type="submit"
                  expand="block"
                  className="custom-button-margin-top"
                  disabled={isDisabled}
                >
                  Save Changes
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

export default SettingsPage;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  useIonToast,
} from '@ionic/react';
import { Auth } from 'aws-amplify';
import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../constants';
import { getToastParams } from '../../utils/toastParams';

const SetPasswordPage: React.FC = () => {
  const history = useHistory();
  const [present, dismiss] = useIonToast();
  const [email, setEmail] = useState<string>('');
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const { confirmation_code } = getQueryParams(params);
  const [code, setCode] = useState<string>(confirmation_code);
  const [newPassword, setNewPassword] = useState<string>('');
  const [canShowForgotPasswordSubmit, setCanShowForgotPasswordSubmit] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  console.log(confirmation_code);
  const forgotPasswordSubmit = async () => {
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, confirmation_code, newPassword);
      present(
        getToastParams(
          {
            message: 'Password changed successfully!',
            onDidDismiss: () => history.goBack(),
          },
          dismiss,
        ),
      );
      resetState();
      history.replace(LOGIN);
    } catch (error: any) {
      console.log('forgotPasswordSubmit', error);
      present(
        getToastParams(
          {
            message: error.message,
          },
          dismiss,
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setCode('');
    setEmail('');
    setNewPassword('');
  };

  //const isDisabled = (canShowForgotPasswordSubmit && !email || !newPassword);

  const handlerOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    forgotPasswordSubmit();
  };

  return (
    <IonPage id="reset-password-page">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <h1>Set your new password</h1>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <form onSubmit={handlerOnSubmit}>
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput
                    required
                    type="email"
                    value={email}
                    onIonChange={e => {
                      setEmail(e.detail.value || '');
                    }}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">New Password</IonLabel>
                  <IonInput
                    required
                    type="password"
                    value={newPassword}
                    onIonChange={e => setNewPassword(e.detail.value || '')}
                  />
                </IonItem>
                <IonButton
                  size="large"
                  type="submit"
                  expand="block"
                  className="custom-button-margin-top"
                  //disabled={isDisabled}
                >
                  Change password
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonLoading isOpen={isLoading} message={'Please wait...'} />
    </IonPage>
  );
};

function getQueryParams(params: any): any {
  return {
    confirmation_code: params.get('confirmation_code'),
    user_name: params.get('user_name'),
  };
}

export default memo(SetPasswordPage);

import { AmplifyS3Image } from '@aws-amplify/ui-react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonText,
} from '@ionic/react';
import { DataStore } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';

import CorrectImage from '../../assets/images/correct.svg';
import IncorrectImage from '../../assets/images/incorrect.svg';
import { PLAYLIST } from '../../constants';
import { Answers, Question, Survey } from '../../models';
import { StudentContext } from '../../reducers/student';
import CustomModal from '../CustomModal';

import './styles.scss';

interface QuestionContainerProps {
  survey?: Survey;
  questions: Question[];
  handlerOnEndContent: () => Promise<boolean>;
}

const QuestionContainer: React.FC<QuestionContainerProps> = ({
  survey,
  questions,
  handlerOnEndContent,
}) => {
  const history = useHistory();
  const [modalComponent, setModalComponent] = useState<React.FC>(() => <div />);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const currentQuestion = questions[currentQuestionIndex];
  const { title, image, options, messageWrong, correctAnswer, messageCorrect } =
    currentQuestion;
  const questionsCount = questions.length;
  const correctOption = correctAnswer && options[correctAnswer - 1];
  const hasNextQuestion = currentQuestionIndex !== questionsCount - 1;
  const { student } = useContext(StudentContext);

  const buildModalCta = (correct = true) => {
    if (!correct) {
      return (
        <IonButton
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Retry
        </IonButton>
      );
    }

    if (hasNextQuestion) {
      return (
        <IonButton
          size="small"
          onClick={() => {
            setShowModal(false);
            handleNextQuestion();
          }}
        >
          Next Question
        </IonButton>
      );
    }

    return (
      <IonButton
        size="small"
        onClick={async () => {
          setShowModal(false);
          saveAnswers();
          const hasNextContent = await handlerOnEndContent();
          if (!hasNextContent) history.replace(PLAYLIST);
        }}
      >
        Finish
      </IonButton>
    );
  };

  const renderOptions = () => {
    return options.map(option => (
      <IonButton
        key={option}
        size="large"
        color="secondary"
        fill={selectedOption === option ? 'solid' : 'outline'}
        expand="block"
        style={{ height: 'auto' }}
      >
        <IonText style={{ paddingTop: 10, paddingBottom: 10 }}>
          {option}
        </IonText>
        <IonRadio value={option} />
      </IonButton>
    ));
  };

  const handleNextQuestion = async () => {
    setSelectedOption('');
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    saveAnswers();
  };

  const saveAnswers = async () => {
    try {
      const original = await DataStore.query(Answers, c =>
        c.question_id('eq', currentQuestion.id),
      );
      if (
        original[0]?.question_id !== currentQuestion.id &&
        original[0]?.student_id !== student?.id
      ) {
        await DataStore.save(
          new Answers({
            question_id: currentQuestion.id,
            student_id: student?.id,
            answer: selectedOption,
          }),
        );
      } else {
        try {
          await DataStore.save(
            Answers.copyOf(original[0], updated => {
              updated.answer = selectedOption;
            }),
          );
        } catch (error) {
          console.log('error updatingAnswer:', error);
        }
      }
    } catch (error) {
      console.log('error savingAnswer:', error);
    }
  };

  const handlerSubmit = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (survey) {
      const { title, image, message } = survey || {};
      setModalComponent(() => (
        <CustomModal
          title={title}
          amplifyImage={image}
          description={message}
          cta={buildModalCta()}
          onDidDismiss={() => setShowModal(false)}
        />
      ));
    } else {
      if (selectedOption === correctOption && messageCorrect) {
        setModalComponent(() => (
          <CustomModal
            title="Yes, correct answer!"
            description={messageCorrect}
            image={CorrectImage}
            cta={buildModalCta()}
            onDidDismiss={() => setShowModal(false)}
          />
        ));
      } else if (messageWrong) {
        setModalComponent(() => (
          <CustomModal
            title="Oops, wrong answer..."
            description={messageWrong}
            image={IncorrectImage}
            cta={buildModalCta(false)}
            onDidDismiss={() => setShowModal(false)}
          />
        ));
      }
    }
    setShowModal(true);
  };

  return (
    <div id="question-container">
      <IonCard>
        <div className="question-count ion-text-center">
          <IonButton disabled>
            Question {currentQuestionIndex + 1}/{questionsCount}
          </IonButton>
        </div>
        <IonCardContent>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol size="4">
                <AmplifyS3Image imgKey={image} />
              </IonCol>
              <IonCol>
                <ReactMarkdown>{title}</ReactMarkdown>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonRadioGroup
        value={selectedOption}
        onIonChange={e => setSelectedOption(e.detail.value)}
      >
        {renderOptions()}
      </IonRadioGroup>
      <IonButton
        size="large"
        expand="block"
        onClick={handlerSubmit}
        disabled={!selectedOption}
      >
        Submit
      </IonButton>

      {showModal && modalComponent}
    </div>
  );
};

export default QuestionContainer;

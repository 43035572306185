import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { memo } from 'react';

import PlaylistCard from '../../components/PlaylistCard';
import type { Playlist } from '../../models';

import './style.scss';

interface PlaylistGridProps {
  title: string;
  playlists: Playlist[];
  completedPlaylists: (string | null)[];
}

const PlaylistGrid: React.FC<PlaylistGridProps> = ({
  title,
  playlists,
  completedPlaylists,
}) => {
  if (!playlists.length) return null;

  return (
    <div id="playlist-grid">
      <IonGrid>
        <IonRow>
          <IonCol>
            <h2>{title}</h2>
          </IonCol>
        </IonRow>
        <IonRow className="playlist-cards">
          {playlists.map(playlist => (
            <IonCol key={playlist.id} size="6">
              <PlaylistCard
                playlist={playlist}
                completed={completedPlaylists.includes(playlist.id)}
              />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default memo(PlaylistGrid);

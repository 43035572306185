import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from '@ionic/react';
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import RootImage from '../../assets/images/gainfull_blue_enough_full.png';
import SplashScreen from '../../components/SplashScreen';
import { LOGIN, REGISTER } from '../../constants';

import './style.scss';

const RootPage: React.FC = () => {
  const history = useHistory();

  const goToPage = (e: React.MouseEvent, page: string) => {
    e.preventDefault();
    history.push(page);
  };

  return (
    <IonPage id="root-page">
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonImg className="ion-padding-vertical" src={RootImage} />
              <p>Big Ideas for Stronger Communities</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                size="large"
                expand="block"
                className="ion-margin-bottom"
                onClick={e => goToPage(e, LOGIN)}
              >
                Login
              </IonButton>
              <IonButton
                size="large"
                fill="outline"
                expand="block"
                onClick={e => goToPage(e, REGISTER)}
              >
                Register
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <SplashScreen />
    </IonPage>
  );
};

export default memo(RootPage);
